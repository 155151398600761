import React, { Component } from 'react';
import { withRouter } from "react-router-dom";


class Countdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
                date:this.props.deadline,
                countdown:{
                    days: 0,
                    hours: 0,
                    min: 0,
                    sec: 0,     
                }
        }
    }

    componentDidMount() {    
        // update every second
        this.interval = setInterval(() => {
            const now = new Date();
            const dead = new Date(this.props.deadline);
            // console.log(now > dead)
            if(now > dead){
                // this.props.history.replace(this.props.redirectUrl)
                this.props.deadFunction()
            }

            const date = this.calculateCountdown(this.props.deadline);
            date ? this.setState({ countdown: date }) : this.stop();
        }, 1000);


    }

    calculateCountdown(endDate) {
        let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000;

        // clear countdown when date is reached
        if (diff <= 0) return false;

        const timeLeft = {            
                years: 0,
                days: 0,
                hours: 0,
                min: 0,
                sec: 0,
                millisec: 0,            
        };

        // calculate time difference between now and expected date
        if (diff >= (365.25 * 86400)) { // 365.25 * 24 * 60 * 60
            timeLeft.years = Math.floor(diff / (365.25 * 86400));
            diff -= timeLeft.years * 365.25 * 86400;
        }
        if (diff >= 86400) { // 24 * 60 * 60
            timeLeft.days = Math.floor(diff / 86400);
            diff -= timeLeft.days * 86400;
        }
        if (diff >= 3600) { // 60 * 60
            timeLeft.hours = Math.floor(diff / 3600);
            diff -= timeLeft.hours * 3600;
        }
        if (diff >= 60) {
            timeLeft.min = Math.floor(diff / 60);
            diff -= timeLeft.min * 60;
        }
        timeLeft.sec = diff;

        return timeLeft;
    }

    stop() {
        clearInterval(this.interval);
    }

    addLeadingZeros(value) {
        value = String(value);
        while (value.length < 2) {
            value = '0' + value;
        }
        return value;
    }


    render() {
        return (
            <div className="countdown-wrapper countdown-order">
                <div className="countdown-div">
                    <div className="number">{this.state.countdown.hours}</div>
                    <div className="label">Jam</div>
                </div>
                <div className="devide">:</div>
                <div className="countdown-div">
                    <div className="number">{this.state.countdown.min}</div>
                    <div className="label">Menit</div>
                </div>
                <div className="devide">:</div>
                <div className="countdown-div">
                    <div className="number">{this.state.countdown.sec}</div>
                    <div className="label">Detik</div>
                </div>
            </div>
        )
    }
}

export default withRouter(Countdown);