import { createStore, applyMiddleware, compose } from "redux";
// import {saveState, loadState} from './services/LocalStorage';
import rootReducer from "./redux-store/redux-reducers";
import { persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native

import thunk from 'redux-thunk';
// import jwt from 'jsonwebtoken';


const configureStore = () => {

    const persistConfig = {
        key: 'root',
        storage,
        blacklist: ['products']
      }

// middleware test 
// const middlewaretest = () => store=> next =>  action=> {
//     const token = store.getState().auth;
   
//     if(token.expire !== null){
//         const now = new Date;
//         const jwtdate = new Date(token.expire)
        
//         if(jwtdate < now) { //expired
//           // store.dispatch({type:"LOGOUT"})
//         }        
//       }

//       next(action);
//       // console.log(token) customertoken
//     // const nexttoken = store.getState().auth;
//     // console.log(nexttoken)
// }

// for redux dev tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedReducer = persistCombineReducers(persistConfig,rootReducer);

//  CreateStore
const store = createStore(persistedReducer,composeEnhancers(applyMiddleware(thunk)));

// const statenya = store.subscribe(()=>{
//     // return store.getState()
//     // saveState({order},1000)
// })

return store;
}

export default configureStore;