import React, { Component } from 'react';
import { FindTransactionId } from "../../../api/index";
import Moment from 'react-moment';
import NumberFormat from 'react-number-format';
import { Br, Cut, Line, Printer, Text, Row, render } from 'react-thermal-printer'
import { NavLink } from "react-router-dom";
import {
    // Input, InputGroup, InputGroupAddon, 
    Button
} from 'reactstrap';

class Invoice extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            data: null,
            date: null,
            isFetching: true,
            OrderInformation: null,
            listProduct: [],
            countdown: {
                days: 0,
                hours: 0,
                min: 0,
                sec: 0,
            },
            listStatusOrder: null,
            isPopUpOpen: false,
            tracking: null,
            isTracking: false
        }
    }



    fecthData() {
        const transactionId = this.props.match.params.id;
        const content = {
            transactionId: transactionId
        }

        FindTransactionId(content).then(res => {
            this.setState({
                data: {
                    subtotal: res[0].subtotal - res[0].value,
                    // ongkir: res[0].value,
                    id: res[0].id,
                    // berat: res[0].weight / 1000,
                    diskon: res[0].diskon,
                    cashback:res[0].cashback,
                    money_received:res[0].money_received,
                    total: res[0].subtotal,
                    // kodeunik: res[0].kode_unik,
                    transactionId: res[0].invoiceNumber,
                    orderDate: res[0].createdAt,
                    // status: res[0].StatusOrder.statusName,
                    // courier: res[0].courier,
                    // address: res[0].address,
                    // shipTo: res[0].Address_Customer.shipTo,
                    // phone: res[0].Address_Customer.phone,
                    createdAt: res[0].createdAt,
                    // dateconfirm: res[0].dateconfirm,
                    // dateproses: res[0].dateproses,
                    // datesend: res[0].datesend,
                    // datereceived: res[0].datereceived,
                    // statusPosition: res[0].status - 1,
                    bank: {
                        bankname: res[0].Bank.bankname,
                        norek: res[0].Bank.norek,
                        image: res[0].Bank.logo,
                        an: res[0].Bank.an,
                    }
                },
                listProduct: res[0].OrderProducts,
                date: res[0].expireDate,
                isFetching: false,
                isLoading: false
            })
        }).catch(err => {
            console.log(err)
        })

    }

    componentDidMount() {
        this.fecthData();
    }

    

    render() {

        

        var { isLoading } = this.state;
        return (
            <>
                {!isLoading ? (

<div className="invoice-wrapper">


<Printer type="epson" width={300} characterSet="korea">
    
    <div class="header"><br />.
    <br />
    Serambi Botani Kafe<br />
    {'  Pasific Place Mall  '} 
    <br />
    0852 1708 0542
    </div>
    <Line />
    Invoice : {this.state.data.transactionId}
    <Br />
    <Moment locale="id" format="DD MMM YYYY, HH:mm">{this.state.data.createdAt}</Moment>
    {/* <Br />
    Time : <Moment locale="id" format="">{this.state.orderDate}</Moment> */}
    
    <Line />           
    {this.state.listProduct.map((value, index) => (
                                                        <tr style={{ fontSize: '14px' }} key={index}>
                                                            <td width="550" style={{
                                                                wordBreak: 'break-word'
                                                            }}>
                                                                <Row left={value.Product.name} right="" />
                                                                {value.qty} {' x '}     
                                                                <NumberFormat value={value.price} displayType={'text'} thousandSeparator={true} prefix={ 'Rp '} />
                                                                {/* {value.Product.name} */}
                                                                <div style={{ margin: '10px 0 0' }}></div>
                                                            </td>
                                                            {/* <td valign="top" style={{ padding: '15px', textAlign: 'center' }}>
                                                                {value.qty}
                                                            </td>
                                                            <td valign="top" style={{ padding: '15px', whiteSpace: 'nowrap', textAlign: 'center' }}>
                                                                <NumberFormat value={value.price} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                            </td>
                                                            <td valign="top" style={{ padding: '15px', whiteSpace: 'nowrap' }}>
                                                                <NumberFormat value={value.price * value.qty} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                            </td> */}
                                                        </tr>
                                                    ))}
    
    <Line />
  
 Subtotal :                                                                
<NumberFormat value={this.state.data.subtotal} displayType={'text'} thousandSeparator={true} prefix={ ' Rp '} />
<Br />
{/* Grand Total :  
<NumberFormat value={this.state.data.subtotal} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
<Br /> */}
Cash :
<NumberFormat value={this.state.data.money_received} displayType={'text'} thousandSeparator={true} prefix={ ' Rp '} />
<Br />
Change :
<NumberFormat value={this.state.data.cashback} displayType={'text'} thousandSeparator={true} prefix={ ' Rp '} />
<Br />

<Br />
    <Text align="center">Harga Sudah Termasuk PPN</Text>
    <Br />
    Terima Kasih <Br />
    www.blst.co.id <Br />
    .
    <Br />
    .

    <Cut />
  </Printer>
                        <div className="no-print" onClick={() => window.print()} style={{
                            width: '100%',
                            textAlign: 'right'
                        }}>
                            <span style={{cursor:'pointer'}}>
                                <i className="fa fa-print" style={{ marginRight: '5px' }} aria-hidden="true"></i> Cetak
                            </span> 
                            
                            
                        </div>
                        <Br />
                        <div className="no-print" style={{
                            width: '100%',
                            textAlign: 'right'
                        }}>
                            <NavLink to="/">
                                    <Button> Kembali</Button>    
                                    </NavLink>
                            
                            
                        </div>

                    </div>
                ) : (
                        <div style={{
                            width: '100%',
                            height: '80vh',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <h1>Generating Invoice...</h1>
                        </div>
                    )}
            </>
        )
    }
}

export default Invoice;