// import {updateObject} from '../utility';
import * as actionType from '../redux-actions/actionTypes';


const initialState = {
    isAuth:false,
    modalShow:false,
    isFetching:false,
    tokenId:null,
    expire:null,
    errorMessageLogin:null,
    errorMessageRegister:null

};

const authReducer = (state=initialState, action) =>{

    switch(action.type){

        case (actionType.OPEN_AUTHMODAL):
        return {
            ...state,
            modalShow:true
        }

        case (actionType.CLOSE_AUTHMODAL):
        return {
            ...state,
            modalShow:false
        }
        
        case (actionType.REGISTER_REQUEST):
        return {
            ...state,
            isFetching:true
        }

        case (actionType.REGISTER_SUCCESS):
        return {
            ...state,
            isAuth:true,
            tokenId:action.tokenId,
            expire: action.expire,
            isFetching:false,
            errorMessageRegister:null
        }

        case (actionType.REGISTER_FAILURE):
        return {
            ...state,
            isAuth:false,
            isFetching:false,
            errorMessageRegister: action.errorMessage
        }

        case (actionType.LOGIN_REQUEST):
        return {
            ...state,
            isFetching:true
        }

        case (actionType.LOGIN_SUCCESS):
        return {
            ...state,
            isAuth:true,
            tokenId:action.tokenId,
            expire: action.expire,
            isFetching:false,
            modalShow:false,
            errorMessageLogin: null
        }

        case (actionType.LOGIN_FAILURE):
        return {
            ...state,
            isAuth:false,
            isFetching:false,
            errorMessageLogin: action.errorMessage
        }

        case (actionType.LOGOUT):
        return {
            ...state,
            isAuth:false,
            isFetching:false,
            tokenId:null,
            expire: null,
            errorMessageLogin: null,
            errorMessageRegister: null
        }

        default:
        return state;
    }
}

export default authReducer;
