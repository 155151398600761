import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FindTransactionId } from "../../api/index";
import NumberFormat from 'react-number-format';
import { withRouter } from "react-router-dom";
import React, { Component } from 'react';
import Moment from 'react-moment';
import 'moment/locale/id';
import { Button } from 'reactstrap';

class Thanks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFetching: true,
            data: {
                subtotal: 0,
                bank: {
                    norek: 0,
                    image: null,
                    an: null,
                }
            },
            date: null,
            countdown: {
                days: 0,
                hours: 0,
                min: 0,
                sec: 0,
            }
        }
    }

    componentDidMount() {
        const transactionId = this.props.match.params.id;
        const content = {
            transactionId: transactionId
        }
        FindTransactionId(content).then(res => {
            // console.log(res)
            this.setState({
                data: {
                    subtotal: res[0].subtotal,
                    bank: {
                        norek: res[0].Bank.norek,
                        image: res[0].Bank.logo,
                        an: res[0].Bank.an,
                    }
                },
                date: res[0].expireDate,                
            })

            // menghindari agar timer tidak terlihat 00:00:00 di awal
            setTimeout(() => {
                this.setState({isFetching: false})
            }, 1000);
        }).catch(err => {
            console.log(err)
        })

        // update every second
        this.interval = setInterval(async () => {
            const now = await new Date();
            const dead = await new Date(this.state.date);
            // if (now > dead) {
            //     this.props.history.replace('/payment/failed/' + transactionId)
            // }

            const date = this.calculateCountdown(this.state.date);
            date ? this.setState({ countdown: date }) : this.stop();
        }, 1000);


    }

    componentWillUnmount() {
        this.stop();
    }

    calculateCountdown(endDate) {
        let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000;

        // clear countdown when date is reached
        if (diff <= 0) return false;

        const timeLeft = {
            years: 0,
            days: 0,
            hours: 0,
            min: 0,
            sec: 0,
            millisec: 0,
        };

        // calculate time difference between now and expected date
        if (diff >= (365.25 * 86400)) { // 365.25 * 24 * 60 * 60
            timeLeft.years = Math.floor(diff / (365.25 * 86400));
            diff -= timeLeft.years * 365.25 * 86400;
        }
        if (diff >= 86400) { // 24 * 60 * 60
            timeLeft.days = Math.floor(diff / 86400);
            diff -= timeLeft.days * 86400;
        }
        if (diff >= 3600) { // 60 * 60
            timeLeft.hours = Math.floor(diff / 3600);
            diff -= timeLeft.hours * 3600;
        }
        if (diff >= 60) {
            timeLeft.min = Math.floor(diff / 60);
            diff -= timeLeft.min * 60;
        }
        timeLeft.sec = diff;

        return timeLeft;
    }

    stop() {
        clearInterval(this.interval);
    }

    addLeadingZeros(value) {
        value = String(value);
        while (value.length < 2) {
            value = '0' + value;
        }
        return value;
    }

    statusPembayaranHandler = (event) => {
        event.preventDefault();
        this.props.history.push(`/order/detail/${this.props.match.params.id}`)
    }


    render() {        
        let boxTimer = <div className="loading-background" style={{ height: "170px", width: "100%", margin: "auto" }}></div>

        if (!this.state.isFetching) {
            boxTimer = <div className="box-timer">
                <div className="timer-title">Sisa waktu pembayaran Anda</div>
                <div className="clock-wrapper">
                    <div className="clock-div">
                        <div className="number">{this.state.countdown.hours}</div>
                        <div className="label">Jam</div>
                    </div>
                    <div className="devide">:</div>
                    <div className="clock-div">
                        <div className="number">{this.state.countdown.min}</div>
                        <div className="label">Menit</div>
                    </div>
                    <div className="devide">:</div>
                    <div className="clock-div">
                        <div className="number">{this.state.countdown.sec}</div>
                        <div className="label">Detik</div>
                    </div>
                </div>
                <div className="timer-title"><i>(Sebelum <Moment locale="id" format="LLLL">{this.state.date}</Moment> WIB)</i></div>
            </div>
        }


        return (
            <div className="thanks-wrapper">
                <div className="inner-thanks-wrapper">
                    <div className="thanks-body">
                        {/* <h3>Segera selesaikan pembayaran Anda sebelum stock habis</h3> */}

                        {/* {boxTimer} */}

                        {/* <div className="norek">

                            <p>Transfer pembayaran ke nomor rekening:</p>
                            <h2>{this.state.data.bank.norek}</h2>
                            <p>{this.state.data.bank.bankname}a/n {this.state.data.bank.an}</p>
                            <CopyToClipboard text={this.state.data.bank.norek.toString().replace(/\./g, '')}>
                                <div className="copy-trigger">Salin Nomor Rekening</div>
                            </CopyToClipboard>
                            <img src={this.state.data.bank.image} alt="" />
                        </div> */}

                        {/* <div className="jyhd">
                            <p>Jumlah yang harus dibayar</p>
                            <div className="nominal-yhd" style={{ textAlign: 'center' }}>
                                <NumberFormat value={this.state.data.subtotal} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                            
                            </div>
                            <div className="highlight-important">
                                <h1>Transfer tepat sampai 3 digit terakhir</h1>
                                <p>Perbedaan nominal harus mengirimkan bukti bayar</p>
                            </div>
                            <CopyToClipboard text={this.state.data.subtotal.toString().replace(/\./g, '')}>
                                <div className="copy-total">Salin Jumlah</div>
                            </CopyToClipboard>
                        </div>

                        <div className="thanks-foot">
                            Pastikan pembayaran Anda sudah Berhasil dan kirim bukti untuk mempercepat proses verifikasi
                        </div> */}

                        <div className="thanks-foot">
                            <Button className="btn-action-big" onClick={(event) => { this.statusPembayaranHandler(event) }}>
                                Lanjutkan Untuk Cetak
                            </Button>
                        </div>


                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Thanks);