// import {updateObject} from '../utility';
import * as actionType from '../redux-actions/actionTypes';


const initialState = {
    products: [],
    totalPrice: 0,
    isFetching: false,
};

const productReducer = (state = initialState, action) => {

    switch (action.type) {
        // menambah volume produk
        case (actionType.START_FETCHPRODUCT):
            return {
                ...state,
                isFetfching: true
            }

        case (actionType.FETCH_PRODUCT):
            return {
                ...state,
                products: action.products
            }

        case (actionType.SUCCESS_FETCHPRODUCT):
            return {
                ...state,
                isFetfching: false
            }

        case (actionType.SET_TOTALPRICE):
            return {
                ...state,
                totalPrice: action.totalPrice
            }

        case (actionType.FLUSH_ORDER):
            return {
                products: [],
                totalPrice: 0,
                isFetching: false,
            }
        case (actionType.UPDATE_TOTALPRICE):
            return {
                ...state,
                totalPrice: action.totalPrice,
            }

        default:
            return state;
    }
}

export default productReducer;
