import React, { Component } from 'react';
import { Container } from 'reactstrap';
import Slider from 'react-slick';
import { withRouter } from "react-router-dom";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getSlidebar } from '../../api/index';



class SliderFront extends Component {
  constructor(props) {
    super(props);
    this.state = {
      main_slider: [],
      static_slider: []
    }
  }

  loadSlidebar = () => {
    // const main_slidebar_preview = [];
    const content = { name: "main" };
    getSlidebar(content)
      .then(result => {
        const main_slider_preview_2 = [];
        if (result.result.length === 0) {
          this.setState({
            main_slider: [],
            main_slider_preview: []
          })
        }
        result.result.map((value, index) => {
          main_slider_preview_2.push({
            id: value.id,
            slidebar: value.slidebar,
            hyperlink: value.hyperlink,
            order: value.order,
            name: value.name
          });
          this.setState({
            main_slider: main_slider_preview_2,
          });
        });
      })
      .then(() => {

      });
  };

  loadSlidebarStatic = () => {
    // const static_slidebar_preview = [];
    const content = { name: "static" };


    getSlidebar(content)
      .then(result => {
        const static_slider_preview_2 = [];

        if (result.result.length === 0) {
          this.setState({
            static_slider: [],
            static_slider_preview: []
          })
        }

        result.result.map((value, index) => {
          static_slider_preview_2.push({
            id: value.id,
            slidebar: value.slidebar,
            hyperlink: value.hyperlink,
            order: value.order,
            name: value.name
          });

          this.setState({
            static_slider: static_slider_preview_2,
            add: false
          });
        });
      })
      .then(() => {

      });
  };

  componentDidMount() {
    this.loadSlidebar()
    this.loadSlidebarStatic()
  }

  onClickSliderWrapperHandler = (e, hyperlink) => {
    e.preventDefault();

    if (hyperlink !== null) {
      const link = hyperlink.split(".com");
      
      if ("https://ipbpress" === link[0] || "https://www.ipbpress" === link[0]) {
        this.props.history.push(link[1]);
      }else{
        window.open(hyperlink, "_blank")
      }
    }
  }

  render() {
    const setting = {
      dots: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      pauseOnHover: true,
      // centerMode: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [{
        breakpoint: 480,
        vertical: true,
        verticalSwiping: true,
      }]
    }

    let slide =
      <div className="item-slider-wrapper">
        <div
          className="loading-background"
          style={{ width: "100%", height: "298px" }}
        />
      </div>;

    if (this.state.main_slider.length !== 0) {
      slide = this.state.main_slider.map((value, index) => (
        <div className="item-slider-wrapper" onClick={(e) => this.onClickSliderWrapperHandler(e, value.hyperlink)} key={index}>
          <div
            className="loading-slider"
            style={{
              width: "100%",
              background:
                'url("' + value.slidebar + '")',
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat"
            }}
          />
        </div>
      ));
    }

    let static_1 = <div
      className="loading-slider loading-background static-slider"
      style={{
        width: "100%",
      }}
    />;

    let static_2 = <div
      className="loading-slider loading-background static-slider"
      style={{
        width: "100%",
      }}
    />;
    // console.log(this.state.static_slider +" ---- "+ this.props.SliderPreview)

    if (this.state.static_slider.slice(0, 1).length > 0) {
      static_1 = this.state.static_slider.slice(0, 1).map((value, index) => (
        <div 
        onClick={(e) => this.onClickSliderWrapperHandler(e, value.hyperlink)}
        key={index}
          className="loading-slider static-slider"
          style={{
            width: "100%",
            background:
              'url("' + value.slidebar + '")',
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat"
          }}
        />
      ))


      if (this.state.static_slider.slice(1, 2).length > 0) {
        static_2 = this.state.static_slider.slice(1, 2).map((value, index) => (
          <div
            onClick={(e) => this.onClickSliderWrapperHandler(e, value.hyperlink)}
            key={index}
            className="loading-slider static-slider"
            style={{
              width: "100%",
              background:
                'url("' + value.slidebar + '")',
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat"
            }}
          />
        ))
      }
    }

    return (
      <Container>
        <div className="slider-wrapper">
          <div className="inner-container-slider">
            <div className="main-slider">
              {/* <img width="740" src="https://ipbstore.com/wp-content/uploads/2019/01/HP-FE-3-1.png" alt=""/> */}
              {/* <div className="loading-slider" style={{ width: '685px', height: '255px' }}></div> */}
              <Slider {...setting}>
                {slide}
              </Slider>
            </div>
            <div className="promo-static-slider">
              <div className="static-slider-item">
                {static_1}
                {/* <div
                                    className="loading-slider static-slider"
                                    style={{
                                        width: '100%',
                                        background: 'url("https://ipbstore.com/wp-content/uploads/2019/01/HP-FE-3-1.png")',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat'
                                    }}>
                                </div> */}
                {/* <img src="" alt=""/> */}
              </div>
              <div className="static-slider-item">
                {static_2}
                {/* <img src="" alt=""/> */}
                {/* <div
                                    className="loading-slider static-slider"
                                    style={{
                                        width: '100%',
                                        background: 'url("https://ipbstore.com/wp-content/uploads/2019/01/HP-FE-3-1.png")',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat'
                                    }}>
                                </div> */}
              </div>
            </div>

          </div>
        </div>
      </Container>
    )
  }
}

export default withRouter(SliderFront);