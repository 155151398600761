import React, { Component } from 'react';
// import { NavLink } from "react-router-dom";


class ProductCardLoading extends Component {

    render() {
        return (
            
                <div className="product-card-wrapper" 
                style={{
                    position: 'relative',
                    width: '157px',
                    height: '300px',
                    marginRight: '5px',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    float: 'left',
                    textDecoration: 'none'}}
               >
                    <div className="product-card-inner">
                        <div className="product-card-display loading-background">

                        </div>
                        <div className="product-title-card">
                            <div className="title-product-card">
                                <span>
                                    <div className="loading-background" 
                                    style={{width:'90%',
                                    height:'15px', 
                                    borderRadius: '10px'}}>
                                    </div>
                                    <div className="loading-background" 
                                    style={{width:'50%',
                                    height:'15px', 
                                    borderRadius: '10px',
                                    marginTop:'5px'
                                    }}>
                                    </div>
                                    
                            </span>
                            </div>
                            <div className="product-price-card">
                            <div className="loading-background" 
                                    style={{width:'90%',
                                    height:'15px', 
                                    borderRadius: '10px'}}>
                                    </div>                                    
                            </div>
                        </div>
                    </div>
                </div>
           
        );
    }
}

export default ProductCardLoading;