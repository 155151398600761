import React, { Component } from 'react';
import LoginRegister from '../../components/LoginRegister/LoginRegister';
import { connect } from 'react-redux';

import {    
    Redirect,
  } from "react-router-dom";


const style= {

    position: 'relative',
    width: '100vw',
    height: '100vh'
}


class SignUpLogIn extends Component {
    constructor(props){
        super(props);
        this.state={
            redirectToReferrer:false,
        }
    }

    componentWillReceiveProps(nextProps) {    
       
        if(nextProps.isAuth){
            this.setState({
                redirectToReferrer:true
            })
        }
    }

    render(){
        const { from } = this.props.location.state || { from: { pathname: "/" } };
        const { redirectToReferrer } = this.state;
        

        if(redirectToReferrer){
            return <Redirect to={from}></Redirect>
        }
        
        return(<div style={{...style}} >
            <LoginRegister/>
        </div>)
    }
}

const mapStateToProps = state => {
    return {
        isModalAuthOpen: state.auth.modalShow,
        isAuth:state.auth.isAuth
    }
}

export default connect(mapStateToProps)(SignUpLogIn);
