import React, { Component } from 'react';
import { Container } from 'reactstrap';
import ProductCard from '../ProductCard/ProductCard';
import Slider from 'react-slick';
import { NavLink, withRouter } from "react-router-dom";
import ProductCardLoading from '../ProductCard/ProductCardLoading';




class ProductSlider extends Component {

    handlinkOnClickThubmnail= (event,hyperlink) => {
        event.preventDefault();
        this.props.history.push(hyperlink);
    }

    render() {
        const setting = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 6,
            slidesToScroll: 3,
            initialSlide: 0,
            centerPadding: '100px',
            variableWidth: true,
            adaptiveHeight: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 839,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 780,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 615,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 2,
                    }
                },

                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                }
            ]
        }

        let listProduct = null;
        let slider = <div className="product-card-slider-loading-wrapper">
            <ProductCardLoading />
            <ProductCardLoading />
            <ProductCardLoading />
            <ProductCardLoading />
            <ProductCardLoading />
            <ProductCardLoading />
        </div>

        if (this.props.products.length > 0) {

            listProduct = this.props.products.map((value, key) => (
                <div key={key}>
                    <div className="product-slide-item">
                        <ProductCard product={value} />
                    </div>
                </div>
            ))

            slider =
                <Slider {...setting}>
                    {listProduct}
                </Slider>
        }

        return (
            <Container>
                <div className="product-slider-wrapper">
                    <div className="inner-product-slider">

                        <div className="product-slider-section-wrapper">
                            {/* <div onClick={(event)=> {this.handlinkOnClickThubmnail(event,this.props.hyperlink)}} className="product-slider-section-title" style={{background:`url(${this.props.thumbnail})`, backgroundSize:'cover',backgroundPosition:'center'}}>

                            </div> */}
                            <div className="product-slick-carousel">
                                <div className="title-section-product-slider">
                                {/* <div className="center-text">
                                    PILIH PRODUK
                                    </div> */}
                                    {/* <div className="loading-background" style={{ width: '300px', height: '20px', marginBottom: '8px' }}></div> */}

                                    <div className="title-section-slider">{this.props.title}</div>
                                    <NavLink to={this.props.hyperlink} style={{ textDecoration: 'none' }}>
                                        <div className="look-all-product-slider">Lihat Semua</div>
                                    </NavLink>
                                </div>

                                <div className="product-slick-carousel-inner">
                                    {slider}
                                   
                                    
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Container>
        );
    }
}

export default withRouter(ProductSlider)