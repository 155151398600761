import React, { Component } from 'react';
import {
    // Input, InputGroup, InputGroupAddon, 
    Button
} from 'reactstrap';
import ProductCard from '../../components/ProductCard/ProductCard';
import { withRouter , NavLink } from 'react-router-dom';
import queryString from 'query-string'
import { SearchBookProduct } from "../../api/index";
import ProductCardLoading from '../../components/ProductCard/ProductCardLoading';
import { ListCategoryCompany } from '../../api/index';

class ProductBook extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobileCategoryFilterOpen: false,
            isFilterSortOpen: false,
            isPriceSortOpen: false,
            isCollapseAllCard: false,
            products: null,
            categories: null,
            isFetching: false,
            queryName: null,
            page: 1,
            count: 'Memuat',
            totalPage: 0,
            filter: {
                byCategory: null,
            },
            sortBy: null,
            minprice: null,
            maxprice: null
        }
    }

    fetchProduct = (payload) => {
        SearchBookProduct(payload).then(result => {
            this.setState({
                products: result.result,
                totalPage: result.pages,
                count: result.count,
                isFetching: false
            })
        });
    }

    doingSearch = () => {
        this.setState({ isFetching: true })
        const query = queryString.parse(this.props.location.search); // ?name=&category=&babibu=

        let byName = '';
        let byminPrice = '';
        let bymaxPrice = '';
        let byCategory = '';
        let bySort = '';
        let page = '';

        if (query.name !== undefined) {
            byName = query.name ? query.name : '';
            byminPrice = query.minprice ? query.minprice : '';
            bymaxPrice = query.maxprice ? query.maxprice : '';
            byCategory = query.category ? query.category : '';
            bySort = query.sortby ? query.sortby : '';
            page = query.page ? query.page : '';
            this.setState({ queryName: byName });
        }

        // jika klik dari menu
        if (this.props.match.params.nameCat !== undefined) {
            byCategory = this.props.match.params.nameCat
            page = query.page ? query.page : 1;
            byminPrice = query.minprice ? query.minprice : '';
            bymaxPrice = query.maxprice ? query.maxprice : '';
            bySort = query.sortby ? query.sortby : '';
            this.setState({ queryName: "Kategori " + byCategory, page: page })
        } else {
            this.setState({ page: Math.ceil(query.page) })
        }


        const content = {
            name: byName,
            minprice: byminPrice,
            maxprice: bymaxPrice,
            category: byCategory,
            sortby: bySort,
            page: page
        }

        this.setState((prevState) => ({
            filter: {
                ...prevState.filter,
                byCategory: content.category
            }
        }));


        // console.log(content)

        this.fetchProduct(content);
    }


    componentDidUpdate(prevProps) {
        if (prevProps.location.search !== this.props.location.search) {
            this.doingSearch();
        }

        if (prevProps.match.params !== this.props.match.params) {
            this.doingSearch();
        }

    }

    // PAGINATION FUNCTION

    changePage = (page) => {
        const search = this.props.location.search; // could be '?foo=bar'
        const params = new URLSearchParams(search);
        params.set('page', page ? page : 1); // bar
        const updatedParams = params.toString();

        this.setState({ page: Math.ceil(page) })

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: updatedParams
        })

    }

    handlePaginationNumberClick = (event, page) => {
        event.preventDefault();
        window.scrollTo(0, 0);
        this.changePage(page)
    }

    handlePaginationArrowClick = (event, action) => {
        window.scrollTo(0, 0);
        const currentPage = this.state.page;

        if (action === "next" && currentPage !== this.state.totalPage) {
            const setPage = Math.ceil(currentPage + 1);
            this.setState({ page: setPage });
            this.changePage(setPage);
        }

        if (action === "previous" && currentPage !== 1) {
            const setPage = Math.ceil(currentPage - 1);
            this.setState({ page: setPage })
            this.changePage(setPage);
        }

    }

    fetchCategories = () => {
        const contents = {
        }

        ListCategoryCompany(contents).then(res => {
            this.setState({ categories: res })
        }).catch(err => console.log(err));
    }

    componentDidMount() {
        this.doingSearch();
        this.fetchCategories();
        window.scrollTo(0, 0);
    }

    onClickCategoryHandler = (event, idCategory, nameCategory) => {
        window.scrollTo(0, 0);
        event.preventDefault();

        // LOGIC
        this.setState((prevState) => ({
            isMobileCategoryFilterOpen: false,
            filter: {
                ...prevState.filter,
                byCategory: nameCategory
            }
        }), () => {

            const search = this.props.location.search; // could be '?foo=bar'
            const params = new URLSearchParams(search);
            params.set('category', this.state.filter.byCategory ? this.state.filter.byCategory : ''); // bar
            params.set('page', 1);
            const updatedParams = params.toString();

            this.props.history.push({
                pathname: this.props.location.pathname,
                search: updatedParams
            })
        })

        // END LOGIC


    }

    deleteAllFilterHandler = (event) => {
        event.preventDefault();

        // LOGIC
        const search = this.props.location.search; // could be '?foo=bar'
        const params = new URLSearchParams(search);
        params.set('category', ''); // bar
        params.set('minprice', '');
        params.set('maxprice', '');
        params.set('name', '');
        params.set('page', 1);
        const updatedParams = params.toString();

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: updatedParams
        })

        // END LOGIC

        this.setState((prevState) => ({
            minprice: null,
            maxprice: null,
            filter: {
                ...prevState.filter,
                byCategory: null,
                byPrice: null,
                byYear: null
            }
        }))
    }

    // handling Sort By

    sortByHandler = (event, string) => {

        this.setState({
            sortBy: string,
            isFilterSortOpen: false,
        }, () => {
            const search = this.props.location.search; // could be '?foo=bar'
            const params = new URLSearchParams(search);
            params.set('sortby', this.state.sortBy ? this.state.sortBy : ''); // bar            
            params.set('page', 1);

            this.changePage(1)
            const updatedParams = params.toString();

            this.props.history.push({
                pathname: this.props.location.pathname,
                search: updatedParams
            })
        })
    }

    onChangeMoneyHandler = (event) => {

        // validasi input tidak boleh huruf
        let values = event.target.value.toString().replace(/,/g, '');

        // let isNum = /^\d+$/.test(values); // tanpakoma
        let isNum = /^[0-9]+\.?[0-9]*$/.test(values); // dengan koma output true or false                 

        let key = event.target.name;
        if (isNum || event.target.value === null) {

            // if (key === "minprice") {
            //     this.setState({ maxprice: Math.ceil(parseInt(values) + 100000) })
            // }
            this.setState({ [key]: (values) })
        }
        else if (values.length <= 1) {
            this.setState({ [key]: 0 })
        }

    }

    formatuang(amount) {
        if (amount === null) {
            amount = 0;
        }
        // deletecomma
        let comadel = amount.toString().replace(/,/g, '');
        let price = comadel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");;
        return price;
    }

    onMinMaxPriceFilter = (event) => {
        event.preventDefault();
        this.setState({ isPriceSortOpen: false })

        const search = this.props.location.search; // could be '?foo=bar'
        const params = new URLSearchParams(search);

        // untuk memunculkan chip tag filter karena kalau ga di un nullin tagnya ga muncul logicnya
        if (this.state.minprice == null) {
            this.setState({ minprice: 0 })
        }

        params.set('minprice', this.state.minprice !== null ? this.state.minprice : 0); // bar
        params.set('maxprice', this.state.maxprice !== null ? this.state.maxprice : 0); // bar
        const updatedParams = params.toString();
        console.log(updatedParams)

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: updatedParams
        })

    }

    onDeleteMixMaxPriceFilter = (event) => {
        event.preventDefault();

        this.setState({ minprice: null, maxprice: null })
        const search = this.props.location.search; // could be '?foo=bar'
        const params = new URLSearchParams(search);
        params.set('minprice', '');
        params.set('maxprice', '');
        const updatedParams = params.toString();

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: updatedParams
        })
    }

    onDeleteKeywordFilter = (event) => {
        event.preventDefault();

        this.setState({ queryName: null, count:'Memuat'});
        const search = this.props.location.search; // could be '?foo=bar'
        const params = new URLSearchParams(search);
        params.set('name', '');        
        const updatedParams = params.toString();

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: updatedParams
        })
    }

    render() {
        let categories = null;

        if (this.state.categories !== null) {
            categories = this.state.categories.map((value, index) => (
                <li key={index} onClick={(event) => { this.onClickCategoryHandler(event, value.id, value.name) }}>
                    <span>
                        <i className="fas fa-atom"></i>
                    </span>
                    <div className="text-filter">
                        {value.name} <div className="chip-count">{value.Products.length}</div>
                    </div>
                </li>
            ))
        }

        let searchResult =
            <>
                <ProductCardLoading></ProductCardLoading>
                <ProductCardLoading></ProductCardLoading>
                <ProductCardLoading></ProductCardLoading>
                <ProductCardLoading></ProductCardLoading>
                <ProductCardLoading></ProductCardLoading>
                <ProductCardLoading></ProductCardLoading>
                <ProductCardLoading></ProductCardLoading>
                <ProductCardLoading></ProductCardLoading>
                <ProductCardLoading></ProductCardLoading>
                <ProductCardLoading></ProductCardLoading>
            </>
            ;

        if (this.state.products !== null && this.state.isFetching === false) {
            searchResult = this.state.products.map((value, index) => (
                <div className="prwc-thumb-wrap" key={index}>
                    <ProductCard product={value} />
                </div>
            ))
        }

        if (this.state.products !== null && this.state.products.length === 0 && this.state.isFetching === false) {
            searchResult = <div className="no-result">
                <h2 style={{ color: 'grey' }}>Oops, hasil pencarian <br /> {this.state.queryName} tidak ditemukan</h2>
            </div>
        }

        // Pagination
        let listpage = []
        // this.state.totalPage
        // case jika halamannya hanya kurang dari 7
        if (this.state.totalPage < 7) {
            for (var i = 1; i <= this.state.totalPage; i++) {
                listpage.push(
                    Math.ceil(i)
                )
            }
        }

        // case halaman awal untuk halaman yang lebih banyak dari 7
        else if (this.state.totalPage > 7 && this.state.page + 3 < 7) {
            for (var i = 1; i <= 7; i++) {
                listpage.push(
                    Math.ceil(i)
                )
            }
        }

        // case jika halamannya lebih dari 7 dan posisi page ada di tengah-tengah
        else if (this.state.page - 3 > 0 && (this.state.totalPage - this.state.page) > 3 && this.state.page + 3 < this.state.totalPage) {
            for (var i = this.state.page - 3; i <= this.state.page + 3; i++) {
                listpage.push(
                    Math.ceil(i)
                )
            }
        }
        // case untuk halaman sudah terakhir
        else {
            for (var i = this.state.totalPage - 7; i <= this.state.totalPage; i++) {
                listpage.push(
                    Math.ceil(i)
                )
            }
        }

        let renderlistpagination = listpage.map((value, index) =>
            (<li onClick={(event) => { this.handlePaginationNumberClick(event, value) }} className={this.state.page === value ? "highligthed" : ""} key={index}>{value}</li>)
        )



        return (
            <div className="product-search-wrapper">
                <div className="product-search-inner">
                    {/* <div className="header-search-product">
                        <div className="column1"><span>Gratis Ongkos Kirim</span></div>
                        <div className="column1"><span>Gratis Ongkos Kirim</span></div>
                        <div className="column1"><span>Gratis Ongkos Kirim</span></div>
                    </div> */}
                    <div className="search-product-container">

                        {/* Column Left */}
                        {/* <div className="search-navigation">
                            <div className="nav-card-wrapper">
                                <div className={this.state.isCollapseAllCard ? "src-nav-card" : "src-nav-card src-nav-colsed"}>
                                    <ul>
                                        <li onClick={(event) => { this.onClickCategoryHandler(event, null, null) }}>Semua Kategori</li>
                                        {categories}
                                    </ul>
                                </div>

                                {!this.state.isCollapseAllCard ? (
                                    <div className="open-all-card" onClick={() => { this.setState({ isCollapseAllCard: !this.state.isCollapseAllCard }) }}>
                                        Tampilkan semua <i style={{ color: '#d8d8d8', marginLeft: '10px' }} className="fas fa-angle-double-down"></i>
                                    </div>
                                ) : (
                                        <div className="open-all-card" onClick={() => { this.setState({ isCollapseAllCard: !this.state.isCollapseAllCard }) }}>
                                            Sembunyikan <i style={{ color: '#d8d8d8', marginLeft: '10px' }} className="fas fa-angle-double-up"></i>
                                        </div>
                                    )}
                            </div>

                            <div className="nav-card-wrapper">
                                <div className="title-section-nav">Harga</div>
                                <div className="src-nav-card">

                                    <form onSubmit={(event) => { this.onMinMaxPriceFilter(event) }} style={{ margin: '20px 0px' }}>
                                        <div className="price-min-wrapper">
                                            <span>min</span>
                                            <input autoComplete="off" name="minprice" value={this.formatuang(this.state.minprice)} onChange={(event) => this.onChangeMoneyHandler(event)} type="text" placeholder="0" />
                                        </div>
                                        <div className="price-min-wrapper">
                                            <span>max</span>
                                            <input autoComplete="off" name="maxprice" value={this.formatuang(this.state.maxprice)} onChange={(event) => this.onChangeMoneyHandler(event)} type="text" placeholder="0" />
                                        </div>
                                        <div className="price-min-wrapper">
                                            <Button type="submit" className="submit-price-filter">Submit</Button>
                                        </div>
                                    </form>

                                </div>
                            </div>


                        </div> */}
                        {/* End Column Left */}


                        <div className="pr-wrapper">
                            <div className="prs-header">
                                {/* <div className="prs-breadcrumb">
                                    <ul>
                                        <li>Home</li>
                                        <li>Book</li>
                                        <li>Pertanian</li>
                                    </ul>
                                </div> */}
                                <div className="filter-nav-mobile">
                                    <div className="button-nav-wrapper">
                                        <Button onClick={(event) => { this.setState({ isPriceSortOpen: !this.state.isPriceSortOpen, isMobileCategoryFilterOpen: false, isFilterSortOpen: false }) }}>
                                            <i className="fas fa-filter"></i> Filter
                                        </Button>
                                        <Button onClick={(event) => { this.setState({ isFilterSortOpen: !this.state.isFilterSortOpen, isMobileCategoryFilterOpen: false, isPriceSortOpen: false }) }}>
                                            <i className="fas fa-align-center"></i> Urutkan
                                        </Button>
                                        <Button onClick={(event) => { this.setState({ isMobileCategoryFilterOpen: !this.state.isMobileCategoryFilterOpen, isFilterSortOpen: false, isPriceSortOpen: false }) }}>
                                            <i className="fas fa-layer-group"></i> Kategori
                                        </Button>

                                        <div className={this.state.isPriceSortOpen ? "mobile-cat-filter open-filter" : "mobile-cat-filter"} style={{ flexDirection: 'column', justifyContent: 'center' }}>
                                            <div className="title-section-nav">Harga</div>
                                            <div className="src-nav-card">
                                                <form onSubmit={(event) => { this.onMinMaxPriceFilter(event) }} style={{ margin: '20px 0px' }}>
                                                    <div className="price-min-wrapper">
                                                        <span>min</span>
                                                        <input autoComplete="off" name="minprice" value={this.formatuang(this.state.minprice)} onChange={(event) => this.onChangeMoneyHandler(event)} type="text" placeholder="0" />
                                                    </div>
                                                    <div className="price-min-wrapper">
                                                        <span>max</span>
                                                        <input autoComplete="off" name="maxprice" value={this.formatuang(this.state.maxprice)} onChange={(event) => this.onChangeMoneyHandler(event)} type="text" placeholder="0" />
                                                    </div>
                                                    <div className="price-min-wrapper">
                                                        <Button style={{ background: 'gainsboro' }} type="submit" className="submit-price-filter">Submit</Button>
                                                    </div>
                                                </form>

                                            </div>
                                        </div>

                                        <div className={this.state.isFilterSortOpen ? "mobile-cat-filter open-filter" : "mobile-cat-filter"}>
                                            <ul>
                                                <li onClick={(event) => { this.sortByHandler(event, "newest") }}>Terbaru</li>
                                                <li onClick={(event) => { this.sortByHandler(event, "lowestprice") }}>Termurah</li>
                                                <li onClick={(event) => { this.sortByHandler(event, "highestprice") }}>Termahal</li>
                                            </ul>
                                        </div>

                                        <div className={this.state.isMobileCategoryFilterOpen ? "mobile-cat-filter open-filter" : "mobile-cat-filter"}>
                                            <ul>
                                                <li onClick={(event) => { this.onClickCategoryHandler(event, null, null) }}>Semua Kategori</li>
                                                {categories}
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="prs-wrap">
                                    <div className="prs-title">{this.state.queryName} {this.state.count} Produk</div>
                                    <NavLink to="/">
                                    <Button> <i className="fas fa-filter"></i>Kembali</Button>    
                                    </NavLink>
                                    {/* <div className={this.state.isFilterSortOpen ? "filter-wrapper filter-open" : "filter-wrapper"}>
                                        <ul>
                                            <li onClick={(event) => { this.sortByHandler(event, "newest") }}>Terbaru</li>
                                            <li onClick={(event) => { this.sortByHandler(event, "lowestprice") }}>Termurah</li>
                                            <li onClick={(event) => { this.sortByHandler(event, "highestprice") }}>Termahal</li>
                                        </ul>
                                    </div> */}
                                </div>

                                {this.state.filter.byCategory !== "" || this.state.minprice !== null || this.state.minprice !== null || this.state.queryName !== "" ?
                                    (
                                        <div className="prs-warp" style={{ marginTop: '10px' }}>
                                            <div className="tag-filter">
                                                <ul className="tag">
                                                    {this.state.queryName !== "" ? (
                                                        <li className="tag-chip">
                                                            <span>Keyword : {this.state.queryName}</span>
                                                            <i onClick={(event) => { this.onDeleteKeywordFilter(event) }} className="fas fa-times"></i>
                                                        </li>
                                                    ) : null}
                                                    {this.state.filter.byCategory !== "" ? (
                                                        <li className="tag-chip">
                                                            <span>{this.state.filter.byCategory}</span>
                                                            <i onClick={(event) => { this.onClickCategoryHandler(event, null, null) }} className="fas fa-times"></i>
                                                        </li>
                                                    ) : null}
                                                    {this.state.minprice !== null && this.state.maxprice !== null ? (
                                                        <li className="tag-chip">
                                                            <span>Rp  {this.formatuang(this.state.minprice)} - Rp {this.formatuang(this.state.maxprice)}</span>
                                                            <i className="fas fa-times" onClick={(event) => { this.onDeleteMixMaxPriceFilter(event) }}></i>
                                                        </li>
                                                    ) : null}

                                                    <li className="tag-chip" onClick={(event) => { this.deleteAllFilterHandler(event) }}>
                                                        <i className="fas fa-trash"></i>
                                                        <span>Hapus Semua Filter</span>
                                                    </li>


                                                </ul>
                                            </div>
                                        </div>
                                    )
                                    : null
                                }


                                <div className="prwc">

                                    {searchResult}

                                </div>
                                <div className="prs-pag-wrap">
                                    <div className="prs-pagination">
                                        <div className="arrow-left" onClick={(event) => this.handlePaginationArrowClick(event, "previous")}><i className="fas fa-chevron-left"></i></div>
                                        <ul>
                                            {renderlistpagination}
                                        </ul>
                                        <div onClick={(event) => this.handlePaginationArrowClick(event, "next")} className="arrow-right"><i className="fas fa-chevron-right"></i></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(ProductBook);