import React from 'react';
import {connect} from 'react-redux';
import * as actionCreator from '../redux-store/redux-actions/index'


import {
    // BrowserRouter as Router,
    Route,
    Redirect,
    // withRouter
  } from "react-router-dom";

const PrivateRoute = ({ component: Component,isAuth,...rest }) => (
    <Route
      {...rest}
      render={props =>
        isAuth ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/signup-login",
              state: { from: props.location }
            }}
          />           
        )
      }
    />
  );

  const mapStateToProps = state => {
      return {
          auth:state.auth
      }
  }

  const mapDispatchToProps = dispatch => {
      return {
        OpenAuthModal: ()=>dispatch(actionCreator.closeModalAuth())
      }
  }

  export default connect(mapStateToProps,mapDispatchToProps)(PrivateRoute);