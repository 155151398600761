import * as actionTypes from './actionTypes';
import {
    RegisterCustomer,
    LoginCustomer,
    SocialMediaLogin
} from '../../api/index';

export const openModalAuth = () => {
    return {
        type: actionTypes.OPEN_AUTHMODAL,
    }
}

export const closeModalAuth = () => {
    return {
        type: actionTypes.CLOSE_AUTHMODAL
    }
}

export const registerRequest = () => {
    return {
        type: actionTypes.REGISTER_REQUEST,
    }
}

export const registerSuccess = (token, expire) => {
    return {
        type: actionTypes.REGISTER_SUCCESS,
        tokenId: token,
        expire: expire
    }
}

export const registerFailure = (message) => {
    return {
        type: actionTypes.REGISTER_FAILURE,
        errorMessage: message
    }
}

export const loginRequest = () => {
    return {
        type: actionTypes.LOGIN_REQUEST,
    }
}

export const loginSuccess = (token, expire) => {
    return {
        type: actionTypes.LOGIN_SUCCESS,
        tokenId: token,
        expire: expire
    }
}

export const loginFailure = (message) => {
    return {
        type: actionTypes.LOGIN_FAILURE,
        errorMessage: message,
    }
}

export const logout = () => {
    return {
        type: actionTypes.LOGOUT,
    }
}

export const RegisterHandler = (content) => {
    const data = {
        ...content
    }

    return dispatch => {
        dispatch(loginRequest());
        dispatch(registerRequest());
        RegisterCustomer(data).then(res => {

            dispatch(registerSuccess(res.token, res.expiresIn));
            dispatch(closeModalAuth());
            //    Promise.all(dispatch1).then(()=>{

            //    })
        }).catch(err => {
            dispatch(registerFailure(err))
        })
    }
}

export const SocialMediaLoginHandler = (content) => {
    const data = {
        ...content
    }

    return dispatch => {
        dispatch(loginRequest());
        SocialMediaLogin(data).then(res=>{
            if (res.code === 200) {
                dispatch(loginSuccess(res.token, res.expiresIn))
                dispatch(closeModalAuth());
            } else {
                dispatch(loginFailure(res.message));
            }
        }).catch(err=>{
            dispatch(loginFailure(err.message));
        })
    }
}

export const LoginHandler = (content) => {
    const data = {
        ...content
    }

    return dispatch => {
        dispatch(loginRequest());
        LoginCustomer(data).then(res => {

            if (res.code === 200) {
                dispatch(loginSuccess(res.token, res.expiresIn))
                dispatch(closeModalAuth());
            } else {
                dispatch(loginFailure(res.message));
            }

            // setTimeout(() => {
            //     dispatch(logout());
            // }, 1000*60*60*24*30); // 1 bulan

        }).catch(err => {
            dispatch(loginFailure(err.message));
        })
    }
}

export const checkToken = () => { 
    return async (dispatch) => {
        const store = await JSON.parse(localStorage.getItem('persist:root'));                
        const auth = JSON.parse(store.auth);        
        const now = new Date();    
        const jwtdate = new Date(auth.expire);
       
        if (jwtdate < now) { //expired            
            // store.dispatch({type:"LOGOUT"})
            dispatch(logout());
        }
    }
}
