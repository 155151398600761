// import {updateObject} from '../utility';
import * as actionType from '../redux-actions/actionTypes';


const initialState = {};

const orderReducer = (state=initialState, action) =>{
    const ProductId = action.ProductId; // destruction taking value from variable method
    const count = state[action.ProductId];    

    switch(action.type){

        // menambah volume produk
        case (actionType.INC_ORDER):
        if(count >= 1) {
            return {
                ...state,
                [ProductId] : count + 1
            }
        }else {
            return {
                ...state,
                [ProductId] : 1
            }
        }

        // mengurangi produk
        case(actionType.DEC_ORDER):
        if(count >1){
            return {
                ...state,
                [ProductId] : count - 1
            }
        }else{
            let newState = {...state};
            delete newState[ProductId];
            return newState;
        }

        // update quantity
        case(actionType.UPDATE_ORDER):
        if(action.qty > 0){
            return {
                ...state,
                [ProductId]:action.qty
            }
        }else{
            let newState = {...state};
            delete newState[ProductId];
            return newState;
        }
        

        // delete order 
        case(actionType.DELETE_ORDER):
        let newState = {...state};
            delete newState[ProductId];
            return newState;

        
        case(actionType.FLUSH_ORDER):
        return {}

        default:
        return state;
    }
}

export default orderReducer;
