import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionCreator from '../../redux-store/redux-actions/index'
import { Button } from 'reactstrap';



class ChooseAddress extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
    }
    

    render() {
        let waiting = <div className="shipped-to-wrapper" style={{ width: '100%' }}>
            <span>
                <div className="loading-background"
                    style={{
                        width: '50%',
                        height: '35px',
                        marginBottom: '10px'
                    }}>
                </div>
                <div className="loading-background"
                    style={{
                        width: '70%',
                        height: '15px',
                        borderRadius: '10px'
                    }}>
                </div>
                <div className="loading-background"
                    style={{
                        width: '50%',
                        height: '15px',
                        borderRadius: '10px',
                        marginTop: '5px'
                    }}>
                </div>
                <div className="loading-background"
                    style={{
                        width: '70%',
                        height: '15px',
                        borderRadius: '10px',
                        marginTop: '5px'
                    }}>
                </div>
            </span>
        </div>

        let lists = this.props.Adresses.map((value, index) => {
           
            return <li className="">
                <div className="name">{value.label}</div>
                <div className="phone">{value.phone}</div>
                <div className="address">
                    {value.address1}
                    <br />
                    {value.subdistrict}
                    <br />
                    {value.city},
                    {value.province}
                </div>

                <div className="button">
                    <Button onClick={(e) => this.props.onClickAddress(e, value)} className="choose-address">Pilih Alamat</Button>
                </div>
            </li>
        })

       

        return (
            <div className="auth-wrapper">
                <div className="auth-box">
                    <div className="inner-box">
                        <div className="header-auth">
                            <h3 style={{ margin: '0' }}>Pilih Alamat</h3>
                            <svg onClick={(event) => this.props.onClose(event)} width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M12 11.293l10.293-10.293.707.707-10.293 10.293 10.293 10.293-.707.707-10.293-10.293-10.293 10.293-.707-.707 10.293-10.293-10.293-10.293.707-.707 10.293 10.293z" /></svg>
                        </div>
                        <div className="form-login">
                            {this.state.isLoading ? (waiting) : (
                                <ul className="address-listwrapper">
                                    {lists}
                                </ul>
                            )}


                        </div>
                    </div>
                </div>
                <div className="backdrop" style={{ top: '0', zIndex: '101' }}></div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        isModalAuthOpen: state.auth.modalShow,
        isFetching: state.auth.isFetching,
        auth: state.auth
    }
}

const mapDispatchToProps = dispatch => {
    return {
        CloseAuthModal: () => dispatch(actionCreator.closeModalAuth()),
        RegisterHandler: (content) => dispatch(actionCreator.RegisterHandler(content)),
        LoginHandler: (content) => dispatch(actionCreator.LoginHandler(content))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ChooseAddress);