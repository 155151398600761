import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Container } from 'reactstrap'

class Footer extends Component {
    constructor(props){
        super(props);
        this.state= {
            belanja: false,
            jelajah:false,
            pembayaran:false,
            pengiriman:false,
            ikutikami:false
        }
    }

    onCollapseHandler=(event, value)=>{
        event.preventDefault();
        this.setState({[value]:!this.state[value]})
    }


    render() {
        
        return (
            <Container>
             

            </Container>
        )
    }
}

export default Footer;