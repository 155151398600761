import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "./assets/store-style.scss";
import * as serviceWorker from './serviceWorker';
import { Router, Route, Switch } from 'react-router-dom';
import { hist } from './services/history';

import StoreLayout from './layouts/store-layout';
import StoreLayoutPerpusnas from './layouts/perpusnas-layout';
import configureStore from './configureStore';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react'
import Loading from './components/Loading/Loading';
import ProductPerpusnas from './views/ProductPerpusnas/ProductPerpusnas';
import ProductNew from './views/ProductNew/ProductNew';

export const store = configureStore();

let persistor = persistStore(store);

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={<Loading/>} persistor={persistor}>
            <Router history={hist}>
                <Switch>
                {/* <Route path="/product/newproduct" component={ProductNew}></Route> */}
                    <Route path="/product/perpusnas" component={ProductPerpusnas}></Route>
                    <Route path="/" component={StoreLayout}></Route>
                </Switch>
            </Router>
        </PersistGate>
    </Provider>

    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

