import axios from 'axios';
import querystring from 'querystring';
import jwt from 'jsonwebtoken';

const HOSTNAME = `${process.env.REACT_APP_API_URL}` || 'http://localhost:8080';
// const HOSTNAME_ONGKIR = process.env.REACT_APP_API_URL_ONGKIR || 'https://pro.rajaongkir.com/api'


let data_user = null;
let tokenSession = null;
let store = {
  "isAuth": false,
  "modalShow": false,
  "isFetching": false,
  "tokenId": null,
  "expire": null,
  "errorMessageLogin": null,
  "errorMessageRegister": null
};

async function getToken() {
 

  store = JSON.parse(localStorage.getItem('persist:root'));

 const auth = JSON.parse(store.auth);
 const token = auth.tokenId;

  const now = new Date();
  const jwtdate = new Date(auth.expire);
  // kalau tokennya ada
  if (token !== null && jwtdate > now) {

    const JWT_DECODE = jwt.verify(token, 'customertoken');
    tokenSession = `Bearer ` + token;
    data_user = {
      customerId: JWT_DECODE.num,
      nameUser: JWT_DECODE.firstname,
      email: JWT_DECODE.email,
    }

    return { tokenSession, data_user }
  }
}


let dataWeb = null;
function getIdentity() {
  return dataWeb = {
    companyId: process.env.REACT_APP_WEB_ID || '21',
  }
}

const API_SERVICES = {
  SlideBarGet: `${HOSTNAME}/slidebar/index`,
  BestSellerIndex: `${HOSTNAME}/product/bestseller`,
  ProductDetail: `${HOSTNAME}/product/api/detail`,
  ProductWhereIn: `${HOSTNAME}/product/wherein`,
  RegisterCustomer: `${HOSTNAME}/customer/add`,
  LoginCustomer: `${HOSTNAME}/customer/login`,
  SocialMediaLogin: `${HOSTNAME}/customer/social-login`,
  CheckEmail: `${HOSTNAME}/checkemail/check`,
  ListKota: `${HOSTNAME}/district-recomendation/query`,
  ListKecamatan: `${HOSTNAME}/district-recomendation/subdistrict`,
  QueryShip: `${HOSTNAME}/district-recomendation/queryship`,
  AddAddress: `${HOSTNAME}/address/add`,
  FindAddress: `${HOSTNAME}/address/index`,
  ListBank: `${HOSTNAME}/bank/index`,
  OrderCreate: `${HOSTNAME}/order/add`,
  FindTransactionId: `${HOSTNAME}/orderproduct/transaction`,
  ListStatusOrder: `${HOSTNAME}/statusorder/index`,
  ListOrderProduct: `${HOSTNAME}/orderproduct/list`,
  SearchQuery: `${HOSTNAME}/product/search`,
  CategoryList: `${HOSTNAME}/category/company`,
  TrackingShip: `${HOSTNAME}/district-recomendation/tracking`,
  SearchPerpusnas: `${HOSTNAME}/product/searchperpusnas`,
  SearchNewProduct: `${HOSTNAME}/product/newproduct`,
  SearchFitsProduct: `${HOSTNAME}/product/fitsproduct`,
  SearchBookProduct: `${HOSTNAME}/product/bookproduct`,
  SearchKitchenProduct: `${HOSTNAME}/product/kitchenproduct`


}


const configFetch = (url, method, body, isJSON = false, extraHeaders = {}) => ({
  method,
  url,
  data: isJSON ? body : querystring.stringify(body),
  headers: {
    'Content-Type': isJSON ? 'application/json' : 'application/x-www-form-urlencoded',
    'Accept': 'application/json',
    ...extraHeaders
  }
});

// SLIDEBAR

const getSlidebar = (contents) => {

  // getIdentity()
  getToken();

  const content = {
    ...contents,
    ...dataWeb
  }

  const url = API_SERVICES.SlideBarGet;
  const extraHeaders = {
    Authorization: `Bearer ` + localStorage.getItem('token')
  }

  return axios(configFetch(url, 'post', content, true, extraHeaders))
    .then(result => result.data)
    .catch(err => console.log(err))
}

// FRONT PAGE
const BestSeller = (contents) => {
  getToken();
  // getIdentity();

  const content = {
    ...contents,
    ...dataWeb
  }

  const url = API_SERVICES.BestSellerIndex;
  const extraHeaders = {
    Authorization: `Bearer ` + localStorage.getItem('token')
  }

  return axios(configFetch(url, 'get', content, true, extraHeaders))
    .then(result => result.data)
    .catch(err => console.log(err))
}

// ...PRODUCT
const ProductDetail = (contents) => {
  getToken();
  // getIdentity();

  const content = {
    ...contents,
    ...dataWeb
  }

  const url = API_SERVICES.ProductDetail + '/' + contents.ProductId;
  const extraHeaders = {
    Authorization: `Bearer ` + localStorage.getItem('token')
  }

  return axios(configFetch(url, 'get', content, true, extraHeaders))
    .then(result => result.data)
    .catch(err => console.log(err))
}

const ProductWhereIn = (contents) => {

  getToken()
  const content = {
    ...contents,
    ...dataWeb,
    ...data_user
  }



  const url = API_SERVICES.ProductWhereIn;
  const extraHeaders = {
    Authorization: `Bearer ` + localStorage.getItem('token')
  }

  return axios(configFetch(url, 'post', content, true, extraHeaders))
    .then(result => result.data)
    .catch(err => console.log(err))
}

// AUTHENTIFICATION
const RegisterCustomer = (contents) => {
  const content = {
    ...contents,
    ...dataWeb
  }

  const url = API_SERVICES.RegisterCustomer;
  const extraHeaders = {
    Authorization: `Bearer ` + localStorage.getItem('token')
  }

  return axios(configFetch(url, 'post', content, true, extraHeaders))
    .then(result => result.data)
    .catch(err => console.log(err))
}

const CheckEmail = (contents) => {
  const content = {
    ...contents,
    ...dataWeb
  }

  const url = API_SERVICES.CheckEmail;
  const extraHeaders = {
    Authorization: `Bearer ` + localStorage.getItem('token')
  }

  return axios(configFetch(url, 'post', content, true, extraHeaders))
    .then(result => result.data)
    .catch(err => console.log(err))
}

const LoginCustomer = (contents) => {
  const content = {
    ...contents,
    ...dataWeb
  }

  const url = API_SERVICES.LoginCustomer;
  const extraHeaders = {
    Authorization: `Bearer ` + localStorage.getItem('token')
  }

  return axios(configFetch(url, 'post', content, true, extraHeaders))
    .then(result => result.data)
    .catch(err => console.log(err))
}

const SocialMediaLogin = (contents) => {

  const content = {
    ...contents,
    ...dataWeb,
    ...data_user
  }

  const url = API_SERVICES.SocialMediaLogin;

  const extraHeaders = {
    Authorization: tokenSession
  }

  return axios(configFetch(url, 'post', content, true, extraHeaders))
    .then(result => result.data)
    .catch(err => console.log(err))
}

const ListKota = (contents) => {
  const content = {
    ...contents,
    ...dataWeb
  }

  const url = API_SERVICES.ListKota;
  const extraHeaders = {
    Authorization: `Bearer ` + localStorage.getItem('token')
  }

  return axios(configFetch(url, 'get', content, true, extraHeaders))
    .then(result => result.data)
    .catch(err => console.log(err))
}

const ListKecamatan = (contents) => {
  const content = {
    ...contents,
    ...dataWeb
  }

  const url = API_SERVICES.ListKecamatan;

  const extraHeaders = {
    Authorization: `Bearer ` + localStorage.getItem('token')
  }

  return axios(configFetch(url, 'post', content, true, extraHeaders))
    .then(result => result.data)
    .catch(err => console.log(err))
}

const FindAddressCustomer = (contents) => {

  getToken()
  const content = {
    ...contents,
    ...dataWeb,
    ...data_user
  }


  const url = API_SERVICES.FindAddress;

  const extraHeaders = {
    Authorization: tokenSession
  }

  return axios(configFetch(url, 'post', content, true, extraHeaders))
    .then(result => result.data)
    .catch(err => console.log(err))
}

// Create Alamat Customer sebelum checkout
const AddAddressCustomer = (contents) => {

  getToken()
  const content = {
    ...contents,
    ...dataWeb,
    ...data_user
  }

  const url = API_SERVICES.AddAddress;

  const extraHeaders = {
    Authorization: tokenSession
  }

  return axios(configFetch(url, 'post', content, true, extraHeaders))
    .then(result => result.data)
    .catch(err => console.log(err))
}

// ketika sudah memilih alamat menghitung ongkos kirimnya
const QueryShip = (contents) => {

  getToken();
  // getIdentity();
  const content = {
    ...contents,
    ...dataWeb,
    ...data_user
  }

  const url = API_SERVICES.QueryShip;

  const extraHeaders = {
    Authorization: tokenSession
  }

  return axios(configFetch(url, 'post', content, true, extraHeaders))
    .then(result => result.data)
    .catch(err => console.log(err))
}

const ListBankCompany = (contents) => {

  getToken();
  // getIdentity();
  const content = {
    ...contents,
    ...dataWeb,
    ...data_user
  }


  const url = API_SERVICES.ListBank;

  const extraHeaders = {
    Authorization: tokenSession
  }

  return axios(configFetch(url, 'post', content, true, extraHeaders))
    .then(result => result.data)
    .catch(err => console.log(err))
}

const OrderCreate = (contents) => {

  getToken();
  // getIdentity();
  const content = {
    ...contents,
    ...dataWeb,
    ...data_user
  }

  const url = API_SERVICES.OrderCreate;
  const extraHeaders = {
    Authorization: tokenSession
  }

  return axios(configFetch(url, 'post', content, true, extraHeaders))
    .then(result => result.data)
    .catch(err => console.log(err))
}

const FindTransactionId = (contents) => {

  getToken();
  // getIdentity();
  const content = {
    ...contents,
    ...dataWeb,
    ...data_user
  }

  const url = API_SERVICES.FindTransactionId;
  const extraHeaders = {
    Authorization: tokenSession
  }

  return axios(configFetch(url, 'post', content, true, extraHeaders))
    .then(result => result.data)
    .catch(err => console.log(err))
}

const ListStatusOrder = (contents) => {

  getToken();
  // getIdentity();
  const content = {
    ...contents,
    ...dataWeb,
    ...data_user
  }

  const url = API_SERVICES.ListStatusOrder;
  const extraHeaders = {
    Authorization: tokenSession
  }

  return axios(configFetch(url, 'get', content, true, extraHeaders))
    .then(result => result.data)
    .catch(err => console.log(err))
}

const ListOrder = (contents) => {

  getToken();
  // getIdentity();
  const content = {
    ...contents,
    ...dataWeb,
    ...data_user
  }

  const url = API_SERVICES.ListOrderProduct;
  const extraHeaders = {
    Authorization: tokenSession
  }

  return axios(configFetch(url, 'post', content, true, extraHeaders))
    .then(result => result.data)
    .catch(err => console.log(err))
}

const SearchQuery = (contents) => {

  getToken();
  // getIdentity();

  const name = contents.name ? contents.name : '';
  const author = contents.author ? contents.author : '';
  const minprice = contents.minprice ? contents.minprice : '';
  const maxprice = contents.maxprice ? contents.maxprice : '';
  const category = contents.category ? contents.category : '';
  const sortby = contents.sortby ? contents.sortby : '';
  const page = contents.page ? contents.page : 1;

  const content = {
    ...contents,
    ...dataWeb,
    ...data_user
  }

  const url = API_SERVICES.SearchQuery + "?name=" + name + "&author=" + author + "&minprice=" + minprice + "&maxprice=" + maxprice + "&category=" + category + "&sortby=" + sortby + "&page=" + page;
  const extraHeaders = {
    Authorization: tokenSession
  }

  return axios(configFetch(url, 'get', content, true, extraHeaders))
    .then(result => result.data)
    .catch(err => console.log(err))
}

const SearchPerpusnas = (contents) => {

  getToken();
  // getIdentity();

  const name = contents.name ? contents.name : '';
  const author = contents.author ? contents.author : '';
  const minprice = contents.minprice ? contents.minprice : '';
  const maxprice = contents.maxprice ? contents.maxprice : '';
  const category = contents.category ? contents.category : '';
  const sortby = contents.sortby ? contents.sortby : '';
  const page = contents.page ? contents.page : 1;

  const content = {
    ...contents,
    ...dataWeb,
    ...data_user
  }

  const url = API_SERVICES.SearchPerpusnas + "?name=" + name + "&author=" + author + "&minprice=" + minprice + "&maxprice=" + maxprice + "&category=" + category + "&sortby=" + sortby + "&page=" + page;
  const extraHeaders = {
    Authorization: tokenSession
  }

  return axios(configFetch(url, 'get', content, true, extraHeaders))
    .then(result => result.data)
    .catch(err => console.log(err))





}


const SearchNewProduct = (contents) => {

  getToken();
  // getIdentity();

  const name = contents.name ? contents.name : '';
  const author = contents.author ? contents.author : '';
  const minprice = contents.minprice ? contents.minprice : '';
  const maxprice = contents.maxprice ? contents.maxprice : '';
  const category = contents.category ? contents.category : '';
  const sortby = contents.sortby ? contents.sortby : '';
  const page = contents.page ? contents.page : 1;

  const content = {
    ...contents,
    ...dataWeb,
    ...data_user
  }

  const url = API_SERVICES.SearchNewProduct + "?name=" + name + "&author=" + author + "&minprice=" + minprice + "&maxprice=" + maxprice + "&category=" + category + "&sortby=" + sortby + "&page=" + page;
  const extraHeaders = {
    Authorization: tokenSession
  }

  return axios(configFetch(url, 'get', content, true, extraHeaders))
    .then(result => result.data)
    .catch(err => console.log(err))





}


const SearchFitsProduct = (contents) => {

  getToken();
  // getIdentity();

  const name = contents.name ? contents.name : '';
  const author = contents.author ? contents.author : '';
  const minprice = contents.minprice ? contents.minprice : '';
  const maxprice = contents.maxprice ? contents.maxprice : '';
  const category = contents.category ? contents.category : '';
  const sortby = contents.sortby ? contents.sortby : '';
  const page = contents.page ? contents.page : 1;

  const content = {
    ...contents,
    ...dataWeb,
    ...data_user
  }

  const url = API_SERVICES.SearchFitsProduct + "?name=" + name + "&author=" + author + "&minprice=" + minprice + "&maxprice=" + maxprice + "&category=" + category + "&sortby=" + sortby + "&page=" + page;
  const extraHeaders = {
    Authorization: tokenSession
  }

  return axios(configFetch(url, 'get', content, true, extraHeaders))
    .then(result => result.data)
    .catch(err => console.log(err))





}


const SearchBookProduct = (contents) => {

  getToken();
  // getIdentity();

  const name = contents.name ? contents.name : '';
  const author = contents.author ? contents.author : '';
  const minprice = contents.minprice ? contents.minprice : '';
  const maxprice = contents.maxprice ? contents.maxprice : '';
  const category = contents.category ? contents.category : '';
  const sortby = contents.sortby ? contents.sortby : '';
  const page = contents.page ? contents.page : 1;

  const content = {
    ...contents,
    ...dataWeb,
    ...data_user
  }

  const url = API_SERVICES.SearchBookProduct + "?name=" + name + "&author=" + author + "&minprice=" + minprice + "&maxprice=" + maxprice + "&category=" + category + "&sortby=" + sortby + "&page=" + page;
  const extraHeaders = {
    Authorization: tokenSession
  }

  return axios(configFetch(url, 'get', content, true, extraHeaders))
    .then(result => result.data)
    .catch(err => console.log(err))





}

const SearchKitchenProduct = (contents) => {

  getToken();
  // getIdentity();

  const name = contents.name ? contents.name : '';
  const author = contents.author ? contents.author : '';
  const minprice = contents.minprice ? contents.minprice : '';
  const maxprice = contents.maxprice ? contents.maxprice : '';
  const category = contents.category ? contents.category : '';
  const sortby = contents.sortby ? contents.sortby : '';
  const page = contents.page ? contents.page : 1;

  const content = {
    ...contents,
    ...dataWeb,
    ...data_user
  }

  const url = API_SERVICES.SearchKitchenProduct + "?name=" + name + "&author=" + author + "&minprice=" + minprice + "&maxprice=" + maxprice + "&category=" + category + "&sortby=" + sortby + "&page=" + page;
  const extraHeaders = {
    Authorization: tokenSession
  }

  return axios(configFetch(url, 'get', content, true, extraHeaders))
    .then(result => result.data)
    .catch(err => console.log(err))





}



const ListCategoryCompany = (contents) => {

  getToken();
  // getIdentity();
  const content = {
    ...contents,
    ...dataWeb,
    ...data_user
  }

  const url = API_SERVICES.CategoryList;

  const extraHeaders = {
    Authorization: tokenSession
  }

  return axios(configFetch(url, 'post', content, true, extraHeaders))
    .then(result => result.data)
    .catch(err => console.log(err))
}

const TrackingShip = (contents) => {

  getToken();
  // getIdentity();
  const content = {
    ...contents,
    ...dataWeb,
    ...data_user
  }

  const url = API_SERVICES.TrackingShip;

  const extraHeaders = {
    Authorization: tokenSession
  }

  return axios(configFetch(url, 'post', content, true, extraHeaders))
    .then(result => result.data)
    .catch(err => console.log(err))
}


export {
  getSlidebar,
  BestSeller,

  ProductDetail,
  ProductWhereIn,

  RegisterCustomer,
  CheckEmail,
  LoginCustomer,
  SocialMediaLogin,

  ListKota,
  ListKecamatan,
  AddAddressCustomer,
  FindAddressCustomer,
  QueryShip,
  ListBankCompany,

  OrderCreate,
  FindTransactionId,
  ListStatusOrder,
  ListOrder,

  SearchQuery,
  ListCategoryCompany,

  TrackingShip,
  SearchPerpusnas,
  SearchNewProduct,
  SearchFitsProduct,
  SearchBookProduct,
  SearchKitchenProduct

}
