import React, { Component } from 'react';
import logo from '../../icon-checkout.svg';
import background from '../../background-checkout.svg'

class ProcessingPayment extends Component {

    render() {

        return (
            <div className="pyrocessing-wrapper">

                <h1>Mohon Tunggu</h1>
                <small>Pembayaran sedang kami proses...</small>
                <div className="bpw-payment">
                    <div className="background-checkout">
                        <div className="logo-checkout floating-animation">
                            <img className="" src={logo} alt="" />
                        </div>
                        <img className="" src={background} alt="" />
                    </div>


                </div>
            </div>
        )
    }
}

export default ProcessingPayment;