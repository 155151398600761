import React, { Component } from 'react';
import { Button, 
    // Container 
} from 'reactstrap';
import { connect } from 'react-redux';
import * as actionCreator from '../../redux-store/redux-actions/index'
// import SocialButton from '../SocialButton/SocialButton';
import {

    ListKecamatan,
    ListKota
} from '../../api/index';



class AddAddress extends Component {
    constructor(props) {
        super(props);
        this.state = {
            queryCity: null,
            querySubdistrict: null,
            searchCity: false,
            searchSubdistrict: false,
            lists: [{
                label: "Please enter 3 or more Characters",
                value: null
            }],
            listsSubdistrict: [{
                label: "Please enter 2 or more Characters",
                value: null
            }],
            selectedCity: null,
            selectedSubdistrict: null,

            label: null,
            name: null,
            phone: null,
            id_city: null,
            id_province: null,
            postal_code: null,
            id_subdistrict: null,
            fulladdress: null,

            labelValid: false,
            nameValid: false,
            phoneValid: false,
            fulladdressValid: false,
            id_cityValid: false,
            id_subdistrictValid: false,

            showError: false,
            formValid: false,

            formErrors: {
                label: "Dibutuhkan label alamat",
                name: "Dibuthkan nama penerima",
                phone: "Dibutuhkan nomor hp yang valid",
                id_city: "Dibutuhkan alamat kota",
                id_subdistrict: "Dibutuhkan data kecamatan",
                fulladdress: "Dibutuhkan alamat lengkap",
            },

            cities: [],
            subdistricts: []

        }

        this.alamatInput = React.createRef();
        this.alamatInputKecamatan = React.createRef();
    }

    componentDidMount() {
        const content = {};
        ListKota(content).then(result => {
            this.setState({ cities: result.data })
        })

    }

    componentDidUpdate() {
        // if (this.state.id_province !== null) {

        //     const content = {
        //         id_province: this.state.id_province,
        //         id_city: this.state.id_city
        //     };
        //     ListKecamatan(content).then(res => {
        //         this.setState({ subdistricts: res.data })
        //     })
        // }
    }


    onChangeAddressInputHandler = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState((prevState) => ({

            ...prevState,
            [name]: value,

        }), () => {
            this.validateFieldRegister(name, value)
        }
        )
    }

    inputPhoneNumberHandler = (event) => {
        event.preventDefault();
        // validasi input tidak boleh huruf
        let values = event.target.value.toString().replace(/,/g, '');

        let isNum = /^\d+$/.test(values); // tanpakoma
        //  let isNum = /^[0-9]+\.?[0-9]*$/.test(values); // dengan koma output true or false                 

        if (isNum || event.target.value === null) {
            this.setState((prevState) => ({
                phone: values
            }))
        } else if (values.length <= 1) {
            this.setState((prevState) => ({
                phone: 0
            }))
        }

        this.validateFieldRegister("phone", values)

        // this.setState({newQty:event.target.value})
    }


    validateFieldRegister(fieldName, value) {
        switch (fieldName) {

            case ("name"):
                const firstNameValid = value.length > 1;
                let condition2 = false;
                if (firstNameValid) {
                    condition2 = true;
                } else {
                    condition2 = false;
                }

                this.setState((prevState) => ({
                    nameValid: condition2
                }), this.validateFormAddress)
                break;


            case ("label"):
                const labelValid = value.length > 1;
                let condition3 = false;
                if (labelValid) {
                    condition3 = true;
                } else {
                    condition3 = false;
                }

                this.setState((prevState) => ({
                    labelValid: condition3
                }), this.validateFormAddress)
                break;

            case ("phone"):
                const phoneValid = value.length > 10;
                const firstLetter = value.charAt(0) === "0";

                let condition4 = false;
                if (phoneValid && firstLetter) {
                    condition4 = true;
                } else {
                    condition4 = false;
                }

                this.setState((prevState) => ({
                    phoneValid: condition4
                }), this.validateFormAddress)
                break;

            case ("fulladdress"):
                const fulladdressValid = value.length > 1;
                let condition5 = false;
                if (fulladdressValid) {
                    condition5 = true;
                } else {
                    condition5 = false;
                }

                this.setState((prevState) => ({
                    fulladdressValid: condition5
                }), this.validateFormAddress)
                break;

            default:
                break;
        }
    }

    validateFormAddress() {
        // console.log(this.state.labelValid ,
        //     this.state.nameValid ,
        //     this.state.phoneValid ,
        //     this.state.fulladdressValid ,
        //     this.state.id_cityValid ,            
        //     this.state.id_subdistrictValid)
        this.setState((prevState) => ({
            formValid:
                this.state.labelValid &&
                this.state.nameValid &&
                this.state.phoneValid &&
                this.state.fulladdressValid &&
                this.state.id_cityValid &&               
                this.state.id_subdistrictValid
        }))
    }


    onClickAddressSearch = (event) => {
        event.preventDefault();
        this.setState({
            searchCity: !this.state.searchCity,
            queryCity: "-",
            id_cityValid: false,
            id_city: null,
            id_subdistrict: null,
            selectedSubdistrict: null
        }, () => {
            this.alamatInput.current.focus();
            this.validateFormAddress();
        })

    }

    onClickAddressSubdistrictSearch = (event) => {
        event.preventDefault();
        this.setState({
            querySubdistrict: "-",
            id_subdistrictValid: false,
            searchSubdistrict: !this.state.searchSubdistrict,
        }, () => {
            this.alamatInputKecamatan.current.focus();
            this.validateFormAddress();
        })
    }


    onSearchHandler = (event) => {
        event.preventDefault();
        const lists = [];
        const value = event.target.value;

        if (value.length >= 3) {

            const newArr = this.state.cities.filter((el) => {
                return new RegExp(value, "i").exec(el.city_name);
            });

            if (newArr.length > 0) {
                newArr.map((value, index) => (
                    lists.push({
                        label: value.province + ", " + value.type + " " + value.city_name,
                        value: value.city_id,
                        id_province: value.province_id,
                        id_city: value.city_id,
                        postal_code: value.postal_code
                    })
                ))
            } else {
                lists.push({
                    label: `No address found`,
                    value: null,
                    id_province: null,
                    id_city: null,
                    postal_code: null
                })
            }
        } else {
            const sum = 3 - value.length;
            lists.push({
                label: `Please enter ${sum} or more Characters`,
                value: null,
                id_province: null,
                id_city: null,
                postal_code: null
            })
        }

        this.setState({
            lists: lists,
            queryCity: event.target.value,
        })
    }

    onSearchSubdistrictHandler = (event) => {
        event.preventDefault();
        const lists = [];
        const value = event.target.value;

        if (value.length >= 2) {

            const newArr = this.state.subdistricts.filter((el) => {
                return new RegExp(value, "i").exec(el.subdistrict_name);
            });

            if (newArr.length > 0) {
                newArr.map((value) => (
                    lists.push({
                        label: value.subdistrict_name,
                        subdistrict_id: value.subdistrict_id,
                    })
                ))
            } else {
                lists.push({
                    label: `No address found`,
                    value: null,
                    id_province: null,
                    id_city: null
                })
            }
        } else {
            const sum = 2 - value.length;
            lists.push({
                label: `Please enter ${sum} or more Characters`,
                value: null,
                id_province: null,
                id_city: null
            })
        }

        this.setState({
            listsSubdistrict: lists,
            querySubdistrict: event.target.value,
        })
    }

    onClickCityHandler = (event, id_city, id_province, value) => {
        event.preventDefault();

        this.setState({
            id_city: id_city,
            id_cityValid: true,
            id_province: id_province,
            postal_code: value.postal_code,
            selectedCity: {
                label: value.label,
                value: id_city,
                id_province: id_province,
                id_city: id_city,
                postal_code: value.postal_code
            },
            searchCity: false
        }, () => {
            this.validateFormAddress();
            const content = {
                // id_province: this.state.id_province,
                // id_city: this.state.id_city
                id_province: id_province,
                id_city: id_city
            };
            ListKecamatan(content).then(res => {
                this.setState({ subdistricts: res.data })
            })
            
        });
    }

    onClickSubdistrictHandler = (event, value) => {
        event.preventDefault();
        this.setState({
            id_subdistrict: value.subdistrict_id,
            id_subdistrictValid: true,
            selectedSubdistrict: {
                label: value.label,
                value: value.id_subdistrict
            },
            searchSubdistrict: false
        },()=>{
            this.validateFormAddress();
        });
    }

    onSubmitAddressButtonHandler = (event) => {
        event.preventDefault();
        if (this.state.formValid) {
            const payload = {
                label: this.state.label,
                name: this.state.name,
                phone: this.state.phone,
                province_id: this.state.id_province,
                city_id: this.state.id_city,
                subdistrict_id: this.state.id_subdistrict,
                postal_code: this.state.postal_code,
                address1: this.state.fulladdress
            }
            this.props.onSubmit(event, payload)
        } else {
            this.setState((prevState) => ({
                showError: true
            }))
        }
    }


    render() {

        let lists_address = this.state.lists.map((value, index) => (
            <li key={index} onClick={(event) => { this.onClickCityHandler(event, value.id_city, value.id_province, value) }}>
                {value.label}
            </li>
        )
        )

        let lists_subdistrict = this.state.listsSubdistrict.map((value, index) => (
            <li key={index} onClick={(event) => { this.onClickSubdistrictHandler(event, value) }}>
                {value.label}
            </li>
        )
        )

        return (
            <div className="auth-wrapper">
                <div className="auth-box">
                    <div className="inner-box">
                        <div className="header-auth">
                            <h3 style={{ margin: '0' }}>Tambah Alamat</h3>
                            <svg onClick={(event) => this.props.onClose(event)} width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M12 11.293l10.293-10.293.707.707-10.293 10.293 10.293 10.293-.707.707-10.293-10.293-10.293 10.293-.707-.707 10.293-10.293-10.293-10.293.707-.707 10.293 10.293z" /></svg>
                        </div>
                        <div className="form-login">
                            <div className="form-container" style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                                <i style={{ top: '22px' }} className="fas fa-tags"></i>

                                <input type="text" value={this.state.label} onChange={(event) => { this.onChangeAddressInputHandler(event) }} placeholder="Label Alamat" name="label" id="" />
                                {this.state.showError && !this.state.labelValid ?
                                    (
                                        <div className="form-error" style={{ top: '15px' }}>{this.state.formErrors.label}</div>
                                    ) :
                                    null
                                }
                                <span style={{ fontSize: '8pt', color: 'rgb(140,140,140)' }}>Contoh: Alamat Rumah, Alamat Kantor, Apartemen, Dropship</span>
                            </div>
                            <div className="form-container">
                                {this.state.name !== null ? (<span className="label">Nama Penerima</span>) : null}
                                <i className="far fa-user"></i>
                                <input value={this.state.name} type="text" name="name" onChange={(event) => { this.onChangeAddressInputHandler(event) }} placeholder="Nama Penerima"></input>
                                {this.state.showError && !this.state.nameValid ?
                                    (
                                        <div className="form-error">{this.state.formErrors.name}</div>
                                    ) :
                                    null
                                }
                            </div>
                            <div className="form-container">
                                {this.state.phone !== null ? (<span className="label">No HP</span>) : null}
                                <i className="fas fa-mobile-alt"></i>
                                <input value={this.state.phone} type="text" name="phone" onChange={(event) => { this.inputPhoneNumberHandler(event) }} placeholder="Nomor Hp"></input>
                                {this.state.showError && !this.state.phoneValid ?
                                    (
                                        <div className="form-error">{this.state.formErrors.phone}</div>
                                    ) :
                                    null
                                }
                            </div>
                            <div className="form-container">
                                {this.state.queryCity !== null ? (<span className="label">Kota</span>) : null}
                                <i className="fas fa-city"></i>
                                {this.state.searchCity ? (
                                    <>
                                        <input type="text" onChange={(event) => { this.onSearchHandler(event) }} placeholder="" className="input-search-alamat" style={{ marginBottom: '0px' }} ref={this.alamatInput} ></input>
                                        <div className="search-alamat-result">
                                            <ul>
                                                {lists_address}
                                                {/* <li>Please enter 3 or more Characters</li> */}
                                            </ul>
                                        </div>
                                    </>

                                ) : (
                                        <>
                                            <div className="search-address-trigger form-control" onClick={(event) => { this.onClickAddressSearch(event) }}>
                                                {this.state.selectedCity !== null ? (this.state.selectedCity.label) : "Kota"}
                                            </div>
                                            {this.state.showError && !this.state.id_cityValid ?
                                                (
                                                    <div className="form-error">{this.state.formErrors.id_city}</div>
                                                ) :
                                                null
                                            }
                                        </>
                                    )}
                            </div>

                            {this.state.id_city !== null ? (
                                <div className="form-container">
                                    {this.state.querySubdistrict !== null ? (<span className="label">Kecamatan</span>) : null}
                                    <i className="fas fa-university"></i>
                                    {this.state.searchSubdistrict ? (
                                        <>
                                            <input type="text" onChange={(event) => { this.onSearchSubdistrictHandler(event) }} placeholder="" className="input-search-alamat" style={{ marginBottom: '0px' }} ref={this.alamatInputKecamatan} ></input>
                                            <div className="search-alamat-result">
                                                <ul>
                                                    {lists_subdistrict}
                                                    {/* <li>Please enter 3 or more Characters</li> */}
                                                </ul>
                                            </div>
                                        </>

                                    ) : (
                                            <>
                                                <div className="search-address-trigger form-control" onClick={(event) => { this.onClickAddressSubdistrictSearch(event) }}>
                                                    {this.state.selectedSubdistrict !== null ? (this.state.selectedSubdistrict.label) : "Kecamatan"}
                                                </div>
                                                {this.state.showError && !this.state.id_subdistrictValid ?
                                                    (
                                                        <div className="form-error">{this.state.formErrors.id_subdistrict}</div>
                                                    ) :
                                                    null
                                                }
                                            </>
                                        )}
                                </div>
                            ) : null}

                            {this.state.id_subdistrict !== null ? (
                                <div className="form-container" style={{ alignItems: 'flex-start' }}>
                                    {this.state.fulladdress !== null ? (<span style={{ top: '15px' }} className="label">Alamat Lengkap</span>) : null}
                                    <i style={{ top: '15px' }} className="far fa-user"></i>
                                    <textarea className="form-control" style={{ height: '80px' }} onChange={(event) => { this.onChangeAddressInputHandler(event) }} type="text" name="fulladdress" placeholder="Alamat Lengkap"></textarea>
                                    {this.state.showError && !this.state.fulladdressValid ?
                                        (
                                            <div className="form-error">{this.state.formErrors.fulladdress}</div>
                                        ) :
                                        null
                                    }
                                </div>
                            ) : null}

                            {this.props.processing ? (
                                <Button className="login-button" disabled>
                                    Memproses....
                                </Button>
                            ) : (
                                <Button className="login-button" onClick={(event) => { this.onSubmitAddressButtonHandler(event) }}>
                                        Tambah Alamat
                                </Button>
                                )}

                        </div>

                    </div>
                </div>
                <div className="backdrop" style={{ top: '0', zIndex: '101' }}></div>
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        isModalAuthOpen: state.auth.modalShow,
        isFetching: state.auth.isFetching,
        auth: state.auth
    }
}

const mapDispatchToProps = dispatch => {
    return {
        CloseAuthModal: () => dispatch(actionCreator.closeModalAuth()),
        RegisterHandler: (content) => dispatch(actionCreator.RegisterHandler(content)),
        LoginHandler: (content) => dispatch(actionCreator.LoginHandler(content))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(AddAddress);