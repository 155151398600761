import React, { Component } from 'react';
import { Container, Button, Input } from 'reactstrap';
import NumberFormat from 'react-number-format';
import Popup from '../../components/Popup/Popup';

// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actionCreator from '../../redux-store/redux-actions/index';
import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet";
// End Redux
import { ProductDetail } from '../../api/index';


class DetailProduct extends Component {
    constructor(props) {
        super(props)
        this.state = {
            product: {},
            isLoading: false,
            main_picture: null,
            isPopUpOpen: false,
            CurrentQty: null,
            newQty: null,
        }

        this.topPageref = React.createRef();
    }

    // componentWillUpdate(){
    //     const CurrentQty = this.props.order[this.state.product.id];
    //     console.log(CurrentQty)
    //     // this.setState({CurrentQty:CurrentQty})
    // }

    componentWillReceiveProps(nextProps) {

        if (nextProps.match.params.id !== this.props.match.params.id) {
            const idProduct = nextProps.match.params.id.split("-")[0];
            this.dataLoadingInit(idProduct);
            // console.log("rerender")
        }

        this.setState({
            CurrentQty: nextProps.order[this.state.product.id],
            newQty: nextProps.order[this.state.product.id]

        })
    }



    componentDidMount() {
        const idProduct = this.props.match.params.id.split("-")[0];
        this.dataLoadingInit(idProduct);

        /// supaya scroll ke atas
        window.scrollTo(0, 0);
        // console.log(this.topPageref)
        // this.topPageref.current.scrollTo(0, 0);        
    }

    dataLoadingInit(idProduct) {
        this.setState({ isLoading: true })
        // console.log(idProduct)
        const content = {
            ProductId: idProduct
        }
        ProductDetail(content).then(res => {
            // console.log(res)
            this.setState({
                product: res,
                isLoading: false,
                main_picture: res.Pictures[0].url_medium,
                CurrentQty: this.props.order[res.id],
                newQty: this.props.order[res.id]
            });
        })
    }

    changeMainPictureHandler = (event, value) => {
        event.preventDefault();
        this.setState({ main_picture: value.url_medium })
    }

    togglePopup = (event) => {
        event.preventDefault();
        this.setState({ isPopUpOpen: false })
    }

    buyNowHandler = (event) => {
        this.setState({ isPopUpOpen: false })
        this.props.IncOrder(this.state.product.id, this.props.order)

    }

    inputChangeHandler = (event) => {
        event.preventDefault();
        // validasi input tidak boleh huruf
        let values = event.target.value.toString().replace(/,/g, '');

        let isNum = /^\d+$/.test(values); // tanpakoma
        //  let isNum = /^[0-9]+\.?[0-9]*$/.test(values); // dengan koma output true or false                 

        if (isNum || event.target.value === null) {
            this.setState({ newQty: parseFloat(values) })
        }
        else if (values.length <= 1) {
            this.setState({ newQty: 0 })
        }

        // this.setState({newQty:event.target.value})
    }

    incOrderHandler = (event, productId, ProductListFromStore) => {
        event.preventDefault();
        const oldQty = this.state.newQty;
        const newQty = oldQty + 1;
        this.setState({ newQty: newQty });
        // this.props.IncOrder(productId, ProductListFromStore)
    }

    decOrderHandler = (event, productId, ProductListFromStore) => {
        event.preventDefault();
        const oldQty = this.state.newQty;
        const newQty = oldQty - 1;
        if (this.state.newQty > 1) {
            this.setState({ newQty: newQty });
        } else {
            console.log(ProductListFromStore)
            this.props.DeleteOrder(productId)
        }
    }

    updateQuantityHandler = (event, productId, qty) => {
        this.props.UpdateOrder(productId, qty);
        this.setState({ CurrentQty: qty })
    }

    render() {
        let price = null;
        let headerPromo = null;
        let authors = null;
        let authorsString = "blst";
        let categories = null;
        let categoriesString = "blst";
        let picture_navigation =
            <ul className="small-img-wrapper">
                <li>
                    <div className="thbn-prod loading-background" ></div>
                </li>
                <li>
                    <div className="thbn-prod loading-background" ></div>
                </li>
            </ul>
        let picture_main = <div className="prmry-thmb loading-background"></div>


        if (this.state.product.Pictures && this.state.product.Pictures.length > 0) {
            picture_navigation = <ul className="small-img-wrapper">
                {this.state.product.Pictures.map((value, key) => {
                    return <li key={key}>
                        <div className="thbn-prod contain-pict"
                            onMouseEnter={(event) => this.changeMainPictureHandler(event, value)}
                            style={{
                                background: `url(${value.url_small})`,

                            }} ></div>
                    </li>
                })}
            </ul>

            picture_main = <div className="prmry-thmb contain-pict" style={{
                background: `url(${this.state.main_picture})`,
            }}></div>;

            authorsString = [];

            authors = this.state.product.Authors.map((value, index) => {
                authorsString.push(value.name)

             return   <div className="author-card" key={index}>{value.name}</div>
            })

            categoriesString = [];

             categories = this.state.product.Categories.map((value, index) => {
                categoriesString.push(value.name)
            
                return   <div className="categorie-card" key={index}>{value.name}</div>

             })
        }


        if (this.state.product.promoPercentage & this.state.product.promoPercentage !== null || this.state.product.promoPercentage > 0) {
            headerPromo =
                <div className="hfsbn">
                    <span> <i className="fas fa-bolt"></i> Promo Price</span>
                </div>

            price =
                <div>

                    <span className="product-price__reduced">
                        <NumberFormat className="ori-price" value={this.state.product.total_price_ppn} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                        <div className="ppd-off">{this.state.product.promoPercentage}% off</div>
                    </span>
                    <span className="product-price__original">
                        <strong>
                            <NumberFormat value={this.state.product.total_price_ppn} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                        </strong>

                    </span>
                </div>

        } else {
            price =
                <span className="product-price">
                    <NumberFormat value={this.state.product.total_price_ppn} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                </span>
        }

        let popup_body =
            <div className="popup_displayproduct">
                <div className="displayProduct background-contain contain-pict" style={{ background: `url(${this.state.product.Pictures ? this.state.product.Pictures[0].url_small : ''})` }}></div>
                <div className="ProductTitle">
                    <span>{this.state.product.name}</span>
                </div>
            </div>

        return (<>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{this.state.product.name}</title>
                <meta name="description" content={this.state.product.description}></meta>
                <meta name="author" content={authorsString.toString()}></meta>
                <meta name="categorie" content={categoriesString.toString()}></meta>
                <meta property="image" content={this.state.main_picture}></meta>

                <meta property="og:title" content={this.state.product.name}></meta>
                <meta property="og:url" content={"https://ipbpress.com/product/"+this.state.product.id+"-"+this.state.product.name}></meta>
                <meta property="og:image" content={this.state.main_picture}></meta>
                <meta property="og:author" content={authorsString.toString()}></meta>
                <meta name="categorie" content={categoriesString.toString()}></meta>
            </Helmet>

            <Container>
                <Popup
                    onClosePopupHandler={this.togglePopup}
                    isOpen={this.state.isPopUpOpen}
                    headerTitle={"Berhasil Ditambahkan"}
                    body={popup_body}
                />
                <div className="product-detail-wrapper" ref={this.topPageref}>
                    <div className="inner-product-detail">
                        <div className="product-image-wrapper">
                            <div className="image-nav">
                                {picture_navigation}
                                {/* <ul className="small-img-wrapper">
                                    <li>
                                        <div className="thbn-prod loading-background" ></div>
                                    </li>
                                    <li>
                                        <div className="thbn-prod loading-background" ></div>
                                    </li>
                                    <li>
                                        <div className="thbn-prod loading-background" ></div>
                                    </li>
                                    <li>
                                        <div className="thbn-prod loading-background" ></div>
                                    </li>
                                </ul> */}
                            </div>
                            <div className="viewers-image">
                                {picture_main}
                            </div>
                        </div>
                        <div className="ptw">

                            {/* Judul */}
                            <div className="pdh">
                                <h1>{this.state.product.name}</h1>
                            </div>

                            {/* Penilaian */}
                            {/* <div className="spdh">
                                <div className="pnln-rtg">
                                    <span style={{ marginRight: '10px' }}>4.5</span>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star-half-alt"></i>
                                    <i className="far fa-star"></i>
                                </div>
                                <div className="pnln-ct">
                                    <span><strong>1</strong> Penilaian</span>
                                </div>
                            </div> */}

                            {/* Harga  */}

                             {this.state.product.promoPrice !==1  ?
                            <div className="prc-ctn">
                            {headerPromo}
                            <div className="prc-wrp">
                                {price}
                            </div>
                        </div> : null
                        } 

                            {/* Action Button */}
                            <div className="cta-wrapper">

                                {
                                    (!this.props.order[this.state.product.id]) ?
                                        (
                                            <>
                                            {this.state.product.promoPrice !== 1 ? 
                                            <Button className="cta-btn" onClick={((event) => { this.buyNowHandler(event) })} >
                                            <span>TAMBAH PRODUK</span>
                                        </Button> :
                                        <div className="pd-author">
                                        <div className="title"><strong>Penerbit</strong></div>
                                        {this.state.product.publisher}
                                        <div className="pd-author">
                                                <div className="title"><strong>Bahasa</strong></div>
                                                {this.state.product.language}
                                                </div>

                                                <div className="pd-author">
                                                <div className="title"><strong>ISBN</strong></div>
                                                {this.state.product.isbn}
                                                </div>
                                                <div className="pd-author">
                                                <div className="title"><strong>Kategori</strong></div>
                                                {categories}
                                                </div>
                                               </div> }
                                                {/* <div className="pd-author">
                                                    <div className="title"><strong>Penulis</strong></div>
                                                    {authors}
                                                </div> */}

                                                
                                            </>
                                        ) :
                                        (
                                            <div className="qty-rwp">
                                                {/* <span>jumlah </span> */}
                                                <div className="hdl-prod-wrp">
                                                    <div className="chg-qty-btn minus" onClick={(event) => { this.decOrderHandler(event, this.state.product.id, this.props.order) }}>
                                                        <i className="fas fa-minus"></i>
                                                    </div>
                                                    <Input className="input-chg-qty"
                                                        // value={this.props.order[this.state.product.id]} 
                                                        value={this.state.newQty}
                                                        onChange={(event) => { this.inputChangeHandler(event) }}
                                                        type="text" />
                                                    <div className="chg-qty-btn plus" onClick={(event) => { this.incOrderHandler(event, this.state.product.id, this.props.order) }}>
                                                        <i className="fas fa-plus"></i>
                                                    </div>
                                                </div>

                                                {this.state.newQty !== this.state.CurrentQty ? (
                                                    <Button className="cta-btn-update" onClick={(event) => { this.updateQuantityHandler(event, this.state.product.id, this.state.newQty) }}>
                                                        <span>Update Quantity</span>
                                                    </Button>
                                                ) : (
                                                        <Button className="cta-btn-update" disabled>
                                                            <span>Update Quantity</span>
                                                        </Button>
                                                    )}

                                            </div>
                                        )

                                }


                            </div>

                            {/* Lanjut Pembayaran */}
                            {(this.props.order[this.state.product.id]) ? (
                                <div className="checkout-wrapper">
                                    <div className="cond-and-term">
                                        Dengan mengklik tombol di bawah, Anda menyetujui Syarat & Ketentuan dan Kebijakan Privasi
                                </div>
                                    <NavLink to="/checkout" style={{ textDecoration: 'none' }}>
                                        <Button className="cta-btn-checkout">
                                            <span>Lanjut Pembayaran <i style={{ marginLeft: '10px' }} className="fas fa-arrow-right"></i></span>
                                        </Button>

                                    </NavLink>

                                    <div className="orback">
                                         <strong onClick={() => { this.props.history.goBack() }} style={{ cursor: 'pointer' }}>Cari Produk lain</strong>
                                    </div>
                                </div>
                            ) : null

                            }

                        </div>
                        {/* end ptw */}

                    </div>

                    {/* <div className="inner-product-detail">
                        <div className="pdesc-wrapper">
                            <div className="pd-phy">
                                <div className="pdescw">
                                    <div className="ptitle">Berat</div>
                                    <div className="pvalue">{this.state.product.weight} g</div>
                                </div>
                                <div className="pdescw">
                                    <div className="ptitle">panjang</div>
                                    <div className="pvalue">{this.state.product.height} cm</div>
                                </div>
                                <div className="pdescw">
                                    <div className="ptitle">lebar</div>
                                    <div className="pvalue">{this.state.product.width} cm</div>
                                </div>
                                <div className="pdescw">
                                    <div className="ptitle">Halaman</div>
                                    <div className="pvalue">{this.state.product.pages}</div>
                                </div>
                                <div className="pdescw">
                                    <div className="ptitle">Tahun Terbit</div>
                                    <div className="pvalue">{new Date(this.state.product.publish_date).getFullYear().toString()}</div>
                                </div>
                            </div>
                            <div className="pd-desc">
                                <div className="title"><strong>Deskripsi</strong></div>
                                <p> {
                                 this.state.product && this.state.product.description && this.state.product.description.replace(/#isbn/g, '')
                                }</p>
                            </div>
                        </div>
                    </div> */}
                </div>

            </Container>
        </>
        )
    }
}

const mapStateToProps = state => {
    return {
        order: state.order
    }
}

const mapDispatchToProps = dispatch => {
    return {
        IncOrder: (idProduct, productlist) => dispatch(actionCreator.incOrderHandler(idProduct, productlist)),
        DecOrder: (idProduct, productlist) => dispatch(actionCreator.decOrderHandler(idProduct, productlist)), // ga dipakai

        // new
        UpdateOrder: (ProductId, qty) => dispatch(actionCreator.updateOrderHandler(ProductId, qty)),
        DeleteOrder: (ProductId) => dispatch(actionCreator.deleteOrderHandler(ProductId))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DetailProduct));