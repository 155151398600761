import React, { Component } from 'react';
import SliderFront from '../../components/SliderFront/SliderFront';
import ProductSlider from '../../components/ProductSlider/ProductSlider';
import { SearchQuery , SearchNewProduct , SearchFitsProduct, SearchBookProduct, SearchKitchenProduct } from '../../api/index';
import queryString from 'query-string'


class FrontPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            bestSeller: [],           
            mostwanted:[],
            newest:[],
            // highestprice:[],
            // lowestprice:[],
             diskon:[],
             newproduct:[],
            // Fitsproduct:[]
        }
    }

    fetchProduct = (payload,sortby) => {
        SearchQuery(payload).then(result => {           
            this.setState({
                [sortby]: result.result,                
            })
        });
    }

    fetchProductSerambi = (payload,sortby) => {
        SearchNewProduct(payload).then(result => {           
            this.setState({
                [sortby]: result.result,                
            })
        });
    }

    fetchProductFits = (payload,sortby) => {
        SearchFitsProduct(payload).then(result => {           
            this.setState({
                [sortby]: result.result,                
            })
        });
    }
    fetchProductBook = (payload,sortby) => {
        SearchBookProduct(payload).then(result => {           
            this.setState({
                [sortby]: result.result,                
            })
        });
    }

    fetchProductKitchen = (payload,sortby) => {
        SearchKitchenProduct(payload).then(result => {           
            this.setState({
                [sortby]: result.result,                
            })
        });
    }

    doingSearch = (sortby) => {
        this.setState({ isFetching: true })
        const query = queryString.parse(this.props.location.search); // ?name=&category=&babibu=

        let byName = '';
        let byminPrice = '';
        let bymaxPrice = '';
        let byCategory = '';
        let bySort = sortby;
        let page = '';

        if (query.name !== undefined) {
            byName = query.name ? query.name : '';
            byminPrice = query.minprice ? query.minprice : '';
            bymaxPrice = query.maxprice ? query.maxprice : '';
            byCategory = query.category ? query.category : '';
            bySort = query.sortby ? query.sortby : '';
            page = query.page ? query.page : '';
            this.setState({ queryName: byName });
        }

        // jika klik dari menu
        if (this.props.match.params.nameCat !== undefined) {
            byCategory = this.props.match.params.nameCat
            page = query.page ? query.page : 1;
            this.setState({ queryName: "Kategori " + byCategory })
        }

        this.setState({ page: Math.ceil(query.page) })

        const content = {
            name: byName,
            minprice: byminPrice,
            maxprice: bymaxPrice,
            category: byCategory,
            sortby: bySort,
            page: page
        }

        this.fetchProduct(content,sortby);
    }

        doingSearchSerambi = (sortBy) => {
            this.setState({ isFetching: true })
            const query = queryString.parse(this.props.location.search); // ?name=&category=&babibu=
    
            let byName = '';
            let byminPrice = '';
            let bymaxPrice = '';
            let byCategory = '';
            let bySort = sortBy;
            let page = '';
    
            if (query.name !== undefined) {
                byName = query.name ? query.name : '';
                byminPrice = query.minprice ? query.minprice : '';
                bymaxPrice = query.maxprice ? query.maxprice : '';
                byCategory = query.category ? query.category : '';
                bySort = query.sortby ? query.sortby : '';
                page = query.page ? query.page : '';
                this.setState({ queryName: byName });
            }
    
            // jika klik dari menu
            if (this.props.match.params.nameCat !== undefined) {
                byCategory = this.props.match.params.nameCat
                page = query.page ? query.page : 1;
                this.setState({ queryName: "Kategori " + byCategory })
            }
    
            this.setState({ page: Math.ceil(query.page) })
    
            const content = {
                name: byName,
                minprice: byminPrice,
                maxprice: bymaxPrice,
                category: byCategory,
                sortby: bySort,
                page: page
            }
    
            this.fetchProductSerambi(content,sortBy);


        }

        doingSearchFits = (sortBy) => {
            this.setState({ isFetching: true })
            const query = queryString.parse(this.props.location.search); // ?name=&category=&babibu=
    
            let byName = '';
            let byminPrice = '';
            let bymaxPrice = '';
            let byCategory = '';
            let bySort = sortBy;
            let page = '';
    
            if (query.name !== undefined) {
                byName = query.name ? query.name : '';
                byminPrice = query.minprice ? query.minprice : '';
                bymaxPrice = query.maxprice ? query.maxprice : '';
                byCategory = query.category ? query.category : '';
                bySort = query.sortby ? query.sortby : '';
                page = query.page ? query.page : '';
                this.setState({ queryName: byName });
            }
    
            // jika klik dari menu
            if (this.props.match.params.nameCat !== undefined) {
                byCategory = this.props.match.params.nameCat
                page = query.page ? query.page : 1;
                this.setState({ queryName: "Kategori " + byCategory })
            }
    
            this.setState({ page: Math.ceil(query.page) })
    
            const content = {
                name: byName,
                minprice: byminPrice,
                maxprice: bymaxPrice,
                category: byCategory,
                sortby: bySort,
                page: page
            }
    
            this.fetchProductFits(content,sortBy);


        }

        doingSearchBook = (sortBy) => {
            this.setState({ isFetching: true })
            const query = queryString.parse(this.props.location.search); // ?name=&category=&babibu=
    
            let byName = '';
            let byminPrice = '';
            let bymaxPrice = '';
            let byCategory = '';
            let bySort = sortBy;
            let page = '';
    
            if (query.name !== undefined) {
                byName = query.name ? query.name : '';
                byminPrice = query.minprice ? query.minprice : '';
                bymaxPrice = query.maxprice ? query.maxprice : '';
                byCategory = query.category ? query.category : '';
                bySort = query.sortby ? query.sortby : '';
                page = query.page ? query.page : '';
                this.setState({ queryName: byName });
            }
    
            // jika klik dari menu
            if (this.props.match.params.nameCat !== undefined) {
                byCategory = this.props.match.params.nameCat
                page = query.page ? query.page : 1;
                this.setState({ queryName: "Kategori " + byCategory })
            }
    
            this.setState({ page: Math.ceil(query.page) })
    
            const content = {
                name: byName,
                minprice: byminPrice,
                maxprice: bymaxPrice,
                category: byCategory,
                sortby: bySort,
                page: page
            }
    
            this.fetchProductBook(content,sortBy);


        }

        doingSearchKitchen = (sortBy) => {
            this.setState({ isFetching: true })
            const query = queryString.parse(this.props.location.search); // ?name=&category=&babibu=
    
            let byName = '';
            let byminPrice = '';
            let bymaxPrice = '';
            let byCategory = '';
            let bySort = sortBy;
            let page = '';
    
            if (query.name !== undefined) {
                byName = query.name ? query.name : '';
                byminPrice = query.minprice ? query.minprice : '';
                bymaxPrice = query.maxprice ? query.maxprice : '';
                byCategory = query.category ? query.category : '';
                bySort = query.sortby ? query.sortby : '';
                page = query.page ? query.page : '';
                this.setState({ queryName: byName });
            }
    
            // jika klik dari menu
            if (this.props.match.params.nameCat !== undefined) {
                byCategory = this.props.match.params.nameCat
                page = query.page ? query.page : 1;
                this.setState({ queryName: "Kategori " + byCategory })
            }
    
            this.setState({ page: Math.ceil(query.page) })
    
            const content = {
                name: byName,
                minprice: byminPrice,
                maxprice: bymaxPrice,
                category: byCategory,
                sortby: bySort,
                page: page
            }
    
            this.fetchProductKitchen(content,sortBy);


        }

    // doingSearchWakaf(){
    //     this.setState({ isFetching: true })
    //     const query = queryString.parse(this.props.location.search); // ?name=&category=&babibu=

    //     let byName = '';
    //     let byminPrice = '';
    //     let bymaxPrice = '';
    //     let byCategory = 'wakaf';
    //     let bySort = 'lowestprice';
    //     let page = '';

    //     if (query.name !== undefined) {
    //         byName = query.name ? query.name : '';
    //         byminPrice = query.minprice ? query.minprice : '';
    //         bymaxPrice = query.maxprice ? query.maxprice : '';
    //         byCategory = query.category ? query.category : '';
    //         bySort = query.sortby ? query.sortby : '';
    //         page = query.page ? query.page : '';
    //         this.setState({ queryName: byName });
    //     }

    //     // jika klik dari menu
    //     if (this.props.match.params.nameCat !== undefined) {
    //         byCategory = this.props.match.params.nameCat
    //         page = query.page ? query.page : 1;
    //         this.setState({ queryName: "Kategori " + byCategory })
    //     }

    //     this.setState({ page: Math.ceil(query.page) })

    //     const content = {
    //         name: byName,
    //         minprice: byminPrice,
    //         maxprice: bymaxPrice,
    //         category: byCategory,
    //         sortby: bySort,
    //         page: page
    //     }       

    //     this.fetchProduct(content,'wakaf');
    // }

    componentDidMount() {
        this.doingSearchFits('mostwanted');
        this.doingSearch('newproduct');
        this.doingSearchBook('newest');
        this.doingSearchKitchen('diskon');
        // this.doingSearch('diskon');
        this.doingSearchSerambi('bestSeller');
        
        // Program Wakaf
        // this.doingSearchWakaf();
    }

    render() {
        return (
            <div>
                {/* <SliderFront /> */}
                {/* <ProductSlider
                    title="Diskon"
                    products={this.state.diskon}
                    hyperlink="/product/search/?name=&page=1&sortby=diskon"
                    thumbnail="https://res.cloudinary.com/blst/image/upload/v1654139569/buku-buku-diskon.jpg"
                /> */}
                {/* <ProductSlider
                    title="Terbaru"
                    products={this.state.newproduct}
                    hyperlink="/product/newproduct/?name=&page=1&sortby=newproduct"
                    thumbnail="https://res.cloudinary.com/blst/image/upload/v1654139568/buku-buku-terbaru.jpg"
                /> */}
                {/* <ProductSlider
                    title="Terpopuler"
                    products={this.state.lowestprice}
                    hyperlink="/product/search/?name=&page=1&sortby=lowestprice"
                    thumbnail="https://res.cloudinary.com/blst/image/upload/v1551935148/slider/buku-terpopuler-ipbpress.jpg"
                />
                <ProductSlider
                    title="Paling Dicari"
                    products={this.state.mostwanted}
                    hyperlink="/product/search/?name=&page=1&sortby=mostwanted"
                    thumbnail="https://res.cloudinary.com/blst/image/upload/v1551935146/slider/buku-palingdicari-ipbpress.jpg"
                /> */}
                <ProductSlider
                    title="Kitchen"
                    products={this.state.diskon}
                    hyperlink="/product/kitchenproduct/?name=&page=1&sortby=newproduct"
                    // thumbnail="https://res.cloudinary.com/blst/image/upload/v1551935149/slider/buku-terlaris-ipbpress.jpg"
                />

                <ProductSlider
                    title="Serambi Botani"
                    products={this.state.bestSeller}
                    hyperlink="/product/newproduct/?name=&page=1&sortby=newproduct"
                    thumbnail="https://res.cloudinary.com/blst/image/upload/v1654139568/buku-buku-terbaru.jpg"
                />
                   <ProductSlider
                    title="Fits Mandiri"
                    products={this.state.mostwanted}
                    hyperlink="/product/fitsproduct/?name=&page=1&sortby=newproduct"
                    thumbnail="https://res.cloudinary.com/blst/image/upload/v1654139568/buku-buku-terbaru.jpg"
                />

                    <ProductSlider
                    title="IPB Press"
                    products={this.state.newest}
                    hyperlink="/product/bookproduct/?name=&page=1&sortby=newproduct"
                    thumbnail="https://res.cloudinary.com/blst/image/upload/v1654139568/buku-buku-terbaru.jpg"
                />
                {/* <ProductSlider
                    title="FITS MANDIRI"
                    products={this.state.bestSeller}
                    hyperlink="/product/search/?name=&page=1&sortby=bestSeller"
                    // thumbnail="https://res.cloudinary.com/blst/image/upload/v1551935149/slider/buku-terlaris-ipbpress.jpg"
                />
                    <ProductSlider
                    title="Serambi Botani"
                    products={this.state.bestSeller}
                    hyperlink="/product/search/?name=&page=1&sortby=bestSeller"
                    // thumbnail="https://res.cloudinary.com/blst/image/upload/v1654139568/buku-buku-terbaru.jpg"
                />
                  <ProductSlider
                    title="KITCHEN"
                    products={this.state.bestSeller}
                    hyperlink="/product/search/?name=&page=1&sortby=bestSeller"
                    // thumbnail="https://res.cloudinary.com/blst/image/upload/v1551935149/slider/buku-terlaris-ipbpress.jpg"
                /> */}
            </div>

        )
    }
}

export default FrontPage;