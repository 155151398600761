import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";


import { getOrderCount, getTotalPrice, updateTotal } from "../../redux-store/redux-reducers";
import * as actionCreator from '../../redux-store/redux-actions/index'
import AddAddress from '../../components/AddAddress/AddAddress';
import {
    AddAddressCustomer,
    FindAddressCustomer,
    QueryShip,
    ListBankCompany,
    OrderCreate

} from '../../api/index';
import ProcessingPayment from '../../components/ProcessingPayment/ProcessingPayment';
import ChooseAddress from '../../components/ChooseAddress/ChooseAddress';



class Checkout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAddAddressOpen: false,
            isChooseAddressOpen: false,
            isCalculatingShip: false,
            saveAddressProcess: false,
            isFetchingAddress: false,
            isFetchingBank: false,
            isProcessingCheckout: false,

            AddressLists: [],
            ShipAddress: null,
            ShipChoice: null,
            PaymentChoice: null,

            notFoundCodeShow: false,
            SelectedShip: {
                srv: null,
                est: null,
                prc: 0,
                weight: 0
            },
            SelectedPayment: {
                bankname: null,
                logo: null,
                norek: null,
                id: null
            },

            SubTotal: this.props.productTotalPrice,
            totalMoney: null,
            cashback: 0,
            PriceOngkir: 0,
            Total: 0,
            Diskon: 0,
            CouponCode: null
        }


    }
    loadAddress() {
        const content = null
        this.setState({ isFetchingAddress: true })
        FindAddressCustomer(content).then((res) => {
            if (res.data.length > 0) {
                const data = res.data[res.data.length - 1];
                this.setState({ ShipAddress: data, AddressLists: res.data }, () => {
                    this.countingShipCost(); // menghitung langsung ongkir alamat terakhir yang dimasukkan               
                    this.loadBank();
                });
            }
        }).then(() => {
            this.setState({ isFetchingAddress: false })
        })
    }

    onClickAddressChooseHadler(e, value) {
        e.preventDefault();
        this.setState({ ShipAddress: value, isChooseAddressOpen: false }, () => {
            this.countingShipCost();
            this.loadBank();
        });
    }

    loadBank() {
        const content = null;
        this.setState({ isFetchingBank: true })
        ListBankCompany(content).then((res) => {
            if (res.data.length > 0) {
                this.setState({
                    PaymentChoice: res.data,
                    SelectedPayment: {
                        bankname: res.data[0].bankname,
                        logo: res.data[0].logo,
                        norek: res.data[0].norek,
                        id: res.data[0].id
                    }
                })
                // console.log(res.data, "bank option");
            }
        }).then(() => {
            this.setState({ isFetchingBank: true })
        });
    }

    countingShipCost() {
        this.setState({ isCalculatingShip: true })
        const contents = {
            subdistrict_id: this.state.ShipAddress.subdistrict_id,
            products: this.props.order
        }

        // Setelah fetching address yang dimiliki konsumen maka dipanggillah
        // query API untuk menhitung Ongkir dan setState pilihan Servis Kurir (Default JNE)
        // langsung juga do this.onClickCourier(event, this.state.ShipChoice[0].code, value) untuk memfetch data ongkos kirim
        QueryShip(contents).then(respon => {
            this.setState({ ShipChoice: respon.data })
            this.setState({ isCalculatingShip: false })
            const service = respon.data[1].code.toUpperCase() + " " + respon.data[1].costs[0].service;
            const estimated = respon.data[1].costs[0].cost[0].etd;
            const priceShipping = respon.data[1].costs[0].cost[0].value;
            const weight = respon.data.weight;


            this.setState({
                SelectedShip: {
                    srv: service,
                    est: estimated,
                    prc: priceShipping,
                    weight: weight
                },
                PriceOngkir: priceShipping
            }, () => {
                this.doCountTotal();
            })

        })
    }

    componentWillReceiveProps(nextProps) {
        setTimeout(() => {
            this.loadBank();
        }, 200)
    }


    componentDidMount() {
        this.setState({ isFetchingAddress: true })
        setTimeout(() => {
            this.loadBank();
        }, 200)
    }

    doCountTotal() {
        const subtotal = this.props.productTotalPrice;
        const shipping = this.state.PriceOngkir;
        const diskon = this.state.Diskon;

        const Total = subtotal + shipping - diskon;
        this.setState({ Total: Total })
    }


    addAddressTrigger = (event) => {
        event.preventDefault();
        this.setState({ isAddAddressOpen: true, isChooseAddressOpen: false })
    }

    chooseAddressTrigger = (event) => {
        event.preventDefault();
        this.setState({ isChooseAddressOpen: true })
    }

    onCloseAddAddressHanlder = (event) => {
        event.preventDefault();
        this.setState({ isAddAddressOpen: false, isChooseAddressOpen: false })
    }

    onSubmitAddressHandler = (event, payload) => {
        event.preventDefault();
        this.setState({ saveAddressProcess: true })
        // console.log(payload)
        AddAddressCustomer(payload).then(res => {
            if (res.status === "success") {
                this.setState({ saveAddressProcess: false, isAddAddressOpen: false, isChooseAddressOpen: false })
                this.loadAddress();
            }
        }).catch(err => {
            console.log(err)
        })

    }

    onClickCourier = (event, courier, value) => {
        event.preventDefault();
        const service = courier.toUpperCase() + " " + value.service;
        const estimated = value.cost[0].etd;
        const priceShipping = value.cost[0].value;

        this.setState((prevState) => ({
            SelectedShip: {
                ...prevState.SelectedShip,
                srv: service,
                est: estimated,
                prc: priceShipping,
            },
            PriceOngkir: priceShipping
        }), () => {
            this.doCountTotal();
        })

    }

    onClickBank = (event, value) => {
        event.preventDefault();

        this.setState({
            SelectedPayment: {
                bankname: value.bankname,
                logo: value.logo,
                norek: value.norek,
                id: value.id
            },
        })

    }

    handlecashier = (event) => {
        event.preventDefault();
        this.setState({ cashback: this.state.totalMoney - this.props.productTotalPrice });
    }


    checkoutHandler = (event) => {
        event.preventDefault();
        this.setState({ isProcessingCheckout: true })

        const content = {
            products: this.props.order,
            bankId: this.state.SelectedPayment.id,
            cashback: this.state.cashback,
            money_received: this.state.totalMoney,
            // weight: this.state.SelectedShip.weight,
            // addressId: this.state.ShipAddress.id,
            // provinceId: this.state.ShipAddress.province_id,
            price: this.props.productTotalPrice,
            // CouponCode: this.state.CouponCode,
            // address: this.state.ShipAddress.address1 + ", " + this.state.ShipAddress.city + ", " + this.state.ShipAddress.province + ", " + this.state.ShipAddress.postal_code,
            // city_id: this.state.ShipAddress.city_id,
            // subdistrict_id: this.state.ShipAddress.subdistrict_id,
            // courier: this.state.SelectedShip.srv,
            // etd: this.state.SelectedShip.est,
            value: this.state.SelectedShip.prc,
        }

        // console.log(content)

        OrderCreate(content).then(hasil => {
            if (hasil.data !== null) {

                this.props.flushOrder();

                setTimeout(() => {
                    this.props.history.replace('/order/invoice/' + hasil.data);
                }, 3000);
            }
        }).catch(err => {
            this.setState({ isProcessingCheckout: false })
            console.log(err)
        })
    }



    render() {
        let waiting = <div className="shipped-to-wrapper">
            <span>
                <div className="loading-background"
                    style={{
                        width: '50%',
                        height: '35px',
                        marginBottom: '10px'
                    }}>
                </div>
                <div className="loading-background"
                    style={{
                        width: '70%',
                        height: '15px',
                        borderRadius: '10px'
                    }}>
                </div>
                <div className="loading-background"
                    style={{
                        width: '50%',
                        height: '15px',
                        borderRadius: '10px',
                        marginTop: '5px'
                    }}>
                </div>
                <div className="loading-background"
                    style={{
                        width: '70%',
                        height: '15px',
                        borderRadius: '10px',
                        marginTop: '5px'
                    }}>
                </div>
            </span>
        </div>

        let listProductInCart = waiting;
        let list_address = waiting;
        let list_courier = waiting;
        let list_payment = waiting;

        if (this.props.productInCart.products.length > 0) {
            listProductInCart = this.props.productInCart.products.map((value, key) => (
                <div className="cart-list-product-wrapper" key={key}
                // onClick={(event) => this.linkToProduct(event, value)}                
                >
                    {/* <div onClick={(event) => { this.DeleteCartProduct(event, value.id) }} className="del-cart-sum">x</div> */}
                    <div className="list-product-pict contain-pict" style={{ background: `url(${value.Pictures[0].url_small})` }} ></div>
                    <div className="product-list-desc-wrapper">
                        <div className="title">{value.name}</div>
                        {/* <div className="category">Kehutanan</div> */}
                        <div className="price">
                            <NumberFormat value={value.promoPrice} displayType={'text'} thousandSeparator={true} prefix={'Rp '} /> x {this.props.order[value.id]} =
                            <NumberFormat value={value.promoPrice * this.props.order[value.id]} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />

                        </div>
                    </div>
                </div>
            ))
        }

        // if (this.state.ShipAddress !== null) {
        //     list_address =
        //         <>
        //             <div className="shipped-to-wrapper">
        //                 <div className="to">{this.state.ShipAddress.shipTo} <span>({this.state.ShipAddress.label})</span></div>
        //                 <div className="phone">{this.state.ShipAddress.phone}</div>
        //                 <div>{this.state.ShipAddress.address1}, {this.state.ShipAddress.subdistrict}</div>
        //                 <div>{this.state.ShipAddress.city}, {this.state.ShipAddress.province} {this.state.ShipAddress.postal_code}</div>
        //             </div>
        //             <div className="shipped-button-action">
        //                 <Button onClick={(event) => { this.addAddressTrigger(event) }}>Gunakan Alamat Baru</Button>
        //                 <Button onClick={(event) => { this.chooseAddressTrigger(event) }}>Pilih Alamat Lain</Button>
        //             </div>
        //         </>
        // } else if (this.state.ShipAddress === null && !this.state.isFetchingAddress) {
        //     list_address =
        //         <>
        //             <h3 style={{ margin: '0px' }}>Tujuan Pengiriman</h3>
        //             <p>Anda belum memiliki data tujuan alamat pengiriman</p>
        //             <Button className="add-alamat-btn" onClick={(event) => { this.addAddressTrigger(event) }} >
        //                 <i style={{ marginLeft: '10px' }} className="fas fa-plus"></i> Tambah Alamat
        //                                 </Button>
        //         </>
        // }


        // if (this.state.ShipChoice !== null) {
        //     for (let i = 0; i < 2; i++) {
        //         let courier = this.state.ShipChoice[i].costs.map((value, index) => (
        //             <div className="courier-wrapper" key={index} onClick={(event) => { this.onClickCourier(event, this.state.ShipChoice[i].code, value) }}>
        //                 <div className="checkradio-courier">
        //                     <div className={this.state.SelectedShip.est === value.cost[0].etd ? "checkradio-courier-circle courier-checked" : "checkradio-courier-circle"} >
        //                         <div className="checkradio-inner-circle"></div>
        //                     </div>
        //                 </div>
        //                 <div className="courier-desc">
        //                     <h3>{this.state.ShipChoice[i].code} {value.service}</h3>
        //                     <span>Diterima dalam {value.cost[0].etd} hari</span>
        //                 </div>
        //                 <div className="courier-price">
        //                     <span>
        //                         <NumberFormat value={value.cost[0].value} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
        //                     </span>
        //                 </div>
        //             </div>
        //         ))
        //         list_courier[i] = courier
        //     }
        // }

        if (this.state.PaymentChoice !== null) {
            list_payment = this.state.PaymentChoice.map((value, index) => (
                <div className="courier-wrapper" key={index} onClick={(event) => { this.onClickBank(event, value) }}>
                    <div className="checkradio-courier">
                        <div className={this.state.SelectedPayment.norek === value.norek ? "checkradio-courier-circle courier-checked" : "checkradio-courier-circle"} >
                            <div className="checkradio-inner-circle"></div>
                        </div>
                    </div>
                    <div className="courier-desc">
                        <h3>{value.bankname}</h3>
                        <span>Verifikasi otomatis</span>
                    </div>
                    <div className="courier-price">
                        <img height="30" src={value.logo} alt="" />
                    </div>
                </div>
            ))
        }


        return (
            <>
                {this.state.isProcessingCheckout ? (
                    <ProcessingPayment />
                ) : null}
                {/* {this.state.isAddAddressOpen ? (
                    <AddAddress
                        processing={this.state.saveAddressProcess}
                        onClose={(event) => { this.onCloseAddAddressHanlder(event) }}
                        onSubmit={this.onSubmitAddressHandler}>

                    </AddAddress>
                ) : null}
                {this.state.isChooseAddressOpen ? (
                    <ChooseAddress
                        onClickAddress={(e,value)=>this.onClickAddressChooseHadler(e,value)}
                        Adresses={this.state.AddressLists}
                        processing={this.state.saveAddressProcess}
                        onClose={(event) => { this.onCloseAddAddressHanlder(event) }}
                        onSubmit={this.onSubmitAddressHandler}>
                    </ChooseAddress>

                ) : null} */}

                <div className="checkout-wrapper-page">
                    <div className="inner-checkout">
                        <div className="header">
                            <h1><b>Checkout</b></h1>
                        </div>
                        <div className="body">
                            <div className="method">
                                <div className="card">
                                    {/* <div className="card-header">
                                        <h3>Alamat Pengiriman</h3>
                                    </div>
                                    <div className="card-body">

                                        {list_address}

                                    </div> */}
                                </div>

                                {/* {this.state.ShipAddress !== null ? (
                                    <>
                                        <div className="card">
                                            <div className="card-header">
                                                <h3>Metode Pengiriman</h3>
                                            </div>
                                            <div className="">
                                                <div className="ss-wrapper">
                                                    <div className="body-sship">
                                                        {list_courier[0]}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="">
                                                <div className="ss-wrapper">
                                                    <div className="body-sship">
                                                        {list_courier[1]}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                       
                                    </>
                                ) : null} */}
                                <div className="card">
                                    <div className="card-header">
                                        <h3>Metode Pembayaran</h3>
                                    </div>
                                    <div className="">
                                        <div className="ss-wrapper">
                                            <div className="body-sship">
                                                {list_payment}
                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </div>


                            <div className="summary">
                                <div className="card">
                                    <div className="card-header">
                                        <h3>Pemesanan</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="item-list">
                                            {listProductInCart}
                                        </div>
                                        <div className="summary-order">
                                            <table>
                                                <tbody>

                                                    <tr>
                                                        <td>Sub Total</td>
                                                        <td>
                                                            <NumberFormat value={this.props.productTotalPrice} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                        </td>
                                                    </tr>
                                                    {/* <tr>
                                                        <td>Ongkos Kirim ({this.state.SelectedShip.weight / 1000}  kg) </td>
                                                        <td>
                                                            <NumberFormat value={this.state.SelectedShip.prc} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                        </td>
                                                    </tr> */}
                                                    {/* <tr>
                                                    <td>Total</td>
                                                    <td>
                                                        <NumberFormat value={this.state.Total} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                    </td>
                                                </tr> */}
                                                    <tr>
                                                        <td>Diskon</td>
                                                        <td>-
                                                            <NumberFormat value={this.state.Diskon} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                        </td>
                                                    </tr>
                                                    {
                                                        this.state.totalMoney != 0 && this.state.cashback != 0 ? <>
                                                            <tr>
                                                                <td>Uang diterima</td>
                                                                <td>
                                                                    <NumberFormat value={this.state.totalMoney} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Uang kembali</td>
                                                                <td>
                                                                    <NumberFormat value={this.state.cashback} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                                </td>
                                                            </tr>
                                                        </>
                                                            : null
                                                    }

                                                </tbody>
                                            </table>
                                        </div>

                                        {this.state.Total > 0 ? (
                                            <div className="total">
                                                <span>Total</span>
                                                <div className="number-total">
                                                    <NumberFormat value={this.state.Total} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                </div>
                                            </div>
                                        ) : null}


                                        <div className="codePromoWrapper">
                                            <div className="code-promo1">
                                                <span className="has">Uang Yang Diterima</span>

                                            </div>
                                            <div className="code-promo2">
                                                <Input className="input-promo" style={{ margin: "0" }} value={this.state.totalMoney} onChange={(e) => this.setState({ totalMoney: e.target.value })}>
                                                </Input>
                                                <Button className="button-kode-promo" onClick={(event) => { this.handlecashier(event) }}>
                                                    Submit
                                                </Button>

                                            </div>
                                            {/* {this.state.notFoundCodeShow ? (
                                                <span style={{ fontSize: '9pt', color: 'red', fontWeight: 700 }}>Kode tidak ditemukan</span>
                                            ) : null} */}
                                        </div>
                                        <div className="pay-now">
                                            {/* {this.state.SubTotal > 0 ? ( */}
                                            <Button className="bayar-sekarang" onClick={(event => { this.checkoutHandler(event) })}>
                                                Bayar Sekarang
                                            </Button>
                                            {/* ) : (
                                                <>
                                                    <Button className="bayar-sekarang" disabled>
                                                        Bayar Sekarang
                                                    </Button>
                                                    <div style={{ fontSize: '9pt', color: 'red', fontWeight: 700, marginTop: '10px' }}>Mohon untuk mengisi alamat dan metode pengiriman </div>
                                                </>
                                            )} */}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        order: state.order,
        productInCart: state.products,
        productTypeCount: getOrderCount(state),
        productTotalPrice: state.products.totalPrice,
        auth: state.auth
    }
}

const mapDispatchToProps = dispatch => {
    return {
        retriveProductCart: () => dispatch(actionCreator.retriveProductCart()),
        DeleteOrder: (ProductId) => dispatch(actionCreator.deleteOrderHandler(ProductId)),
        OpenAuthModal: () => dispatch(actionCreator.openModalAuth()),
        flushOrder: () => dispatch(actionCreator.flushOrder()),
        UpdateTotalPrice: (value) => dispatch(actionCreator.updateTotalPrice(value)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Checkout));

