import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { FindTransactionId } from "../../api/index";
// import Moment from 'react-moment';
import 'moment/locale/id';
// import NumberFormat from 'react-number-format';


class FailedPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFetching: true,
            data: {
                subtotal:0,
            },
            date: null,
            invoiceNumber:null,
            countdown: {
                days: 0,
                hours: 0,
                min: 0,
                sec: 0,
            }
        }
    }

    componentDidMount() {
        // console.log(this.props.failed)
        const transactionId = this.props.match.params.id;
        const content = {
            transactionId: transactionId
        }
        FindTransactionId(content).then(res => {
            this.setState({
                data: {
                    subtotal:res[0].subtotal
                },
                date: res[0].expireDate,
                invoiceNumber:res[0].invoiceNumber,
                isFetching: false
            })
        }).catch(err => {
            console.log(err)
        })

        // setTimeout(()=>{
        //     this.props.history.replace('/')
        // }, 10000)

    }


    render() {


        return (
            <div className="thanks-wrapper">
                <div className="inner-thanks-wrapper">
                    <div className="thanks-body">
                        <h1 style={{ textAlign: 'center' }}> <i style={{ color: 'red' }} className="far fa-times-circle"></i> Transaksi BERHASIL</h1>

                        <div className="norek">
                            <p style={{ textAlign: 'center' }}>Id Transaksi:</p>
                            <h2 style={{ textAlign: 'center' }}>{this.state.invoiceNumber}</h2>
                        </div>

                        {/* <div className="jyhd">
                            <p style={{ textAlign: 'center' }}>Total Pembayaran</p>
                            <div style={{ textAlign: 'center' }} className="nominal-yhd">
                                
                                    <NumberFormat value={this.state.data.subtotal} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                               
                            </div>
                        </div> */}

                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(FailedPayment);