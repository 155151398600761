import React,{Component} from 'react';

class Loading extends Component {

    render(){
        return(
            <div className="preload-wrapper">
                <h2>Loading</h2>
            </div>
        )
    }
}

export default Loading;