import * as actionTypes from './actionTypes';
import {
    retriveProductCart,
    getTotalPrice} from './product-action';

export const incOrder = (ProductId) => {
    return {
        type:actionTypes.INC_ORDER,
        ProductId:ProductId
    }
}

export const decOrder = (ProductId) => {
    return {
        type:actionTypes.DEC_ORDER,
        ProductId:ProductId
    }
}

export const updateOrder = (ProductId, qty) => {
    return {
        type: actionTypes.UPDATE_ORDER,
        ProductId: ProductId,
        qty:qty
    }
}

export const deleteOrder = (ProductId) => {
    return {
        type: actionTypes.DELETE_ORDER,
        ProductId: ProductId        
    }
}

export const flushOrder = ()=>{
    return{
        type:actionTypes.FLUSH_ORDER
    }
}

export const updateOrderHandler = (ProductId, qty)=>{
    return dispatch => {
        const st1 = dispatch(updateOrder(ProductId,qty));        
        const st2 = dispatch(retriveProductCart()); // update setiap penambahan cart
        Promise.all([st1,st2]).then(()=> {
            dispatch(getTotalPrice())
        })
    }
}

export const deleteOrderHandler = (ProductId)=>{
    return dispatch => {
        const st1 = dispatch(deleteOrder(ProductId));        
        const st2 = dispatch(retriveProductCart()); // update setiap penambahan cart
        Promise.all([st1,st2]).then(()=> {
            dispatch(getTotalPrice())
        })
    }
}



export const incOrderHandler = (productId, productlist) => {
    return dispatch => {        
        const st1 = dispatch(incOrder(productId));        
        const st2 = dispatch(retriveProductCart()); // update setiap penambahan cart
        Promise.all([st1,st2]).then(()=> {
            dispatch(getTotalPrice())
        })
        
    }
}

export const decOrderHandler = (productId, productlist) => {
    return dispatch => {
        
        const st1 = dispatch(decOrder(productId));
        const st2 = dispatch(retriveProductCart()); // update setiap penambahan cart 
        Promise.all([st1,st2]).then(()=> {
            dispatch(getTotalPrice())
        })  
    }
}